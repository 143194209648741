import React, { useState, useRef } from 'react';
import './SignUp.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import Warning from '../../components/Warning/Warning';
import { Helmet } from 'react-helmet';
import { BASE_URL, cookieFetcher } from '../../helper';
import { v4 as uuidv4 } from 'uuid';
import { useEffect } from 'react';


function SignUp() {
  const inputRef = useRef();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [bName, setBName] = useState('');
  const [uName, setUName] = useState('');
  const [mobile, setMobile] = useState('');
  const [shopCategoryIds, setShopCategoryIds] = useState([]);
  const [photo, setPhoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uNameWarning, setUNameWarning] = useState(false);
  const [bNameWarning, setBNameWarning] = useState(false);
  const [shopCategories, setShopCategories] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const token = cookieFetcher('refreshToken');

  useEffect(() => {
    const fetchShopCategories = async () => {
      try {
        const response = await fetch(`${BASE_URL}/shop_category/shop`);
        const data = await response.json();
        setShopCategories(data);
        console.log("shop cat",data);
      } catch (error) {
        console.error('Error fetching shop categories:', error);
      }
    };

    fetchShopCategories();
  }, []);

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const onPhotoChange = (e) => {
    if (e.target && e.target.files) {
      setPhoto(e.target.files[0]);
    }
  };

  const onDeletePhoto = () => {
    setPhoto(null);
  };

    const handleCategoryChange = (e) => {
      const options = Array.from(e.target.options);
      const selectedValues = options
        .filter((option) => option.selected)
        .map((option) => option.value);
      setShopCategoryIds(selectedValues);
  };
  
    const handleCategorySelect = (category) => {
      setShopCategoryIds([...shopCategoryIds, category._id]);
      setSelectedCategory(category);
      setIsDropdownOpen(false);
    };

    const handleDropdownToggle = () => {
      setIsDropdownOpen(!isDropdownOpen);
    };

    const handleRemoveCategory = (categoryId) => {
      setShopCategoryIds(shopCategoryIds.filter((id) => id !== categoryId));
    };
  

  const profileSubmitter = async () => {
    const values = {
      phone: mobile,
      username: uName,
      shop_category_id: shopCategoryIds,
      business_name: bName,
      photo,
      first_name: firstName,
      surname_name: lastName,
    };
    console.log("hi", values);

    if (bName.trim() === '') {
      setBNameWarning(true);
    }
    if (uName.trim() === '') {
      setUNameWarning(true);
    }
    if (bName.trim() === '' || uName.trim() === '') {
      return;
    }

    const formdata = new FormData();
    formdata.append('phone', mobile);
    formdata.append('username', uName);
    formdata.append('shop_category_id', shopCategoryIds);
    formdata.append('business_name', bName);
    formdata.append('photo', photo);
    formdata.append('first_name', firstName);
    formdata.append('surname_name', lastName);

    setLoading(true);
    try {
      const response = await fetch('https://betabackend.lofaz.in/api/shop/signup', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formdata,
      });

      const res = await response.json();
      setLoading(false);
      if (response.ok) {
        window.location.href = '/home';
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong!',
          text: `${response.status}: ${res.message}`,
        });
        console.error(`Error: ${response.status}`, res);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: `${error.message}`,
      });
      console.error('Error:', error);
    }
  };

  return (
    <div className="signupPage bg-gray-100 min-h-screen flex items-center justify-center">
      <Helmet title="Sign Up" />
      <div className="signUpForm bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
        <div className="signUpFormTitle mb-4">
          <h4 className="text-xl font-semibold">
            Create Your Business Profile
          </h4>
        </div>
        <div className="signUpFormContent space-y-2">
          <div className="signUpPhoto flex justify-center mb-4">
            <div className="signUpPhotoHolder relative bg-white border border-gray-300 shadow-sm rounded-xl p-2 flex flex-col items-center">
              <div className="relative">
                <img
                  src={
                    photo
                      ? URL.createObjectURL(photo)
                      : "https://ipfs.filebase.io/ipfs/QmfMyWSPdhgPmF8b3oMh6aQZrnJKT5VREFL2dHAC6U2vgV"
                  }
                  className="h-32 w-32 rounded-full object-cover cursor-pointer"
                  onClick={onButtonClick}
                  alt="Profile"
                />
                <div className="absolute bottom-0 left-2 right-2 flex justify-center items-center space-x-6 opacity-70">
                  <div className="signUpdeleteIcon">
                    <FontAwesomeIcon
                      icon={faTrash}
                      color="red"
                      style={{
                        background: "black",
                        padding: "10px",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                      onClick={onDeletePhoto}
                    />
                  </div>

                  <div className="signUpEditIcon">
                    <FontAwesomeIcon
                      icon={faEdit}
                      color="#009ef7"
                      style={{
                        background: "black",
                        padding: "10px",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                      onClick={onButtonClick}
                    />
                    <input
                      type="file"
                      ref={inputRef}
                      style={{ display: "none" }}
                      onChange={onPhotoChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="signUpFormContentLine flex space-x-2">
            <div className="signUpInputs flex-1">
              <label className="block mb-1">First Name</label>
              <input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First Name"
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="signUpInputs flex-1">
              <label className="block mb-1">Last Name</label>
              <input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last Name"
                className="w-full p-2 border rounded"
              />
            </div>
          </div>
          <div className="signUpFormContentLine flex space-x-4">
            <div className="signUpInputs flex-1">
              <label className="block mb-1">Business Name</label>
              <input
                value={bName}
                onChange={(e) => {
                  if (bName.trim().length > 0) {
                    setBNameWarning(false);
                  }
                  setBName(e.target.value);
                }}
                placeholder="Business Name"
                className="w-full p-2 border rounded"
              />
              {bNameWarning && <Warning type="businessName" />}
            </div>
            <div className="signUpInputs flex-1">
              <label className="block mb-1">Username</label>
              <input
                value={uName}
                onChange={(e) => {
                  if (uName.trim().length > 0) {
                    setUNameWarning(false);
                  }
                  setUName(e.target.value);
                }}
                placeholder="Username"
                className="w-full p-2 border rounded"
              />
              {uNameWarning && <Warning type="username" />}
            </div>
          </div>
          <div className="signUpFormContentLine flex space-x-4">
            <div className="signUpInputs flex-1">
              <label className="block mb-1">Mobile Number</label>
              <input
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                placeholder="Mobile Number"
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="signUpInputs flex-1">
              <label className="block mb-1">Select Shop Categories</label>
              <div className="relative">
                <button
                  onClick={handleDropdownToggle}
                  className="w-full p-2 border rounded flex justify-between items-center"
                >
                  {shopCategoryIds.length > 0
                    ? shopCategories
                        .filter((cat) => shopCategoryIds.includes(cat._id))
                        .map((cat) => cat.title)
                        .join(", ")
                    : ""}
                  <span className="ml-2">&#9662;</span>
                </button>
                {isDropdownOpen && (
                  <div className="absolute bg-white border border-gray-300 rounded mt-1 w-full z-10">
                    {shopCategories.map((category) => (
                      <div
                        key={category._id}
                        onClick={() => handleCategorySelect(category)}
                        className="p-2 hover:bg-gray-200 cursor-pointer"
                      >
                        {category.title}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {shopCategoryIds.length > 0 && (
                <div className="mt-2">
                  {shopCategoryIds.map((id) => {
                    const category = shopCategories.find(
                      (cat) => cat._id === id
                    );
                    return (
                      <span
                        key={id}
                        className="inline-block mr-2 my-1 px-2 py-1 bg-gray-200 text-gray-800 rounded"
                      >
                        {category.title}
                        <span
                          onClick={() => handleRemoveCategory(id)}
                          className="ml-2 cursor-pointer text-red-500"
                        >
                          &#10005;
                        </span>
                      </span>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="signUpSubmit mt-4">
          <button
            className={`w-full py-2 rounded text-white ${
              loading ? "bg-gray-400" : "bg-blue-500"
            }`}
            onClick={profileSubmitter}
            disabled={loading}
          >
            {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
