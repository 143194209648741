import Swal from 'sweetalert2'
import { BASE_URL, cookieFetcher } from '../../helper'
import { redirect } from 'react-router-dom'

export const createProduct = async (
  featureImage,
  images,
  productCategory,
  productName,
  productStatus,
  unit,
  salesPrice,
  mrp,
  description,
  stock,
  label,
  setNameWarning,
  setPriceWarning,
  setLoading,
  setMrpAndPriceWarning
) => {
  if (productName.trim().length === 0 && mrp === 0) {
    setLoading(false)
    setNameWarning(true)
    setPriceWarning(true)
    return
  }
  // if (productName.trim().length === 0) {
  //   setLoading(false)
  //   setNameWarning(true)
  //   return
  // }
  if (mrp === 0) {
    setLoading(false)
    setNameWarning(true)
    return
  }
  if (mrp < salesPrice) {
    setLoading(false)
    setMrpAndPriceWarning(true)
    return
  }
  setLoading(true)
  const formdata = new FormData()
  if (featureImage) {
    formdata.append('photo', featureImage)
  }
  if (salesPrice === 0 && mrp != 0) {
    salesPrice = mrp
  }
  images.map((item) => {
    if (item) {
      formdata.append('photo', item)
    }
  })
  formdata.append('title', productName)
  formdata.append('status', productStatus)
  formdata.append('cat_id', productCategory)
  formdata.append('desc', description)
  formdata.append('mrp', mrp)
  formdata.append('sales_price', salesPrice)
  formdata.append('trends', label)
  formdata.append('unit', unit)
  formdata.append('unit_count', stock)

  const response = await fetch(`${BASE_URL}/vendor/catalog/product/create`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${cookieFetcher('token')}`,
    },
    body: formdata, // Use the FormData object as the request body
  })
  const data = await response.json()
  if (data.status === 200) {
    Swal.fire({
      icon: 'success',
      title: 'Product Created',
      text: `Successfully Created ${data.data.title}!`,
      confirmButtonText: 'Got to Products!',
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = '/products'
      }
    })
    // document.location.href = '/products'
  } else {
    Swal.fire({
      icon: 'fail',
      title: 'Failed try again',
      text: `${data.statusCode} : ${data?.message}`,
    })
  }
}
