import React from 'react'
import { BsShop, BsTools } from 'react-icons/bs'
import { FaBoxes } from 'react-icons/fa'
import { FiSettings } from 'react-icons/fi'
import { MdOutlineInventory } from 'react-icons/md'
import { PiSquaresFour } from 'react-icons/pi'
import { RiUserSettingsLine } from 'react-icons/ri'
import './SmallSidebar.css'
import { useNavigate } from 'react-router-dom'

function SmallSidebar() {
  let navigate = useNavigate()
  return (
    <div className='smallSidebar'>
      <div className='smallSidebarTop'>
        <div className='smallSidebarHeader'></div>
        <div className='smallSidebarButtons'>
          <div className='sidebarButtonIcon' onClick={() => {
                        navigate('/')
                      }}>
            {' '}
            <span class="material-symbols-outlined">
                      home
                    </span>
          </div>
          <div className='sidebarButtonIcon' onClick={() => {
                        navigate('/orders')
                      }}>
            <span class="material-symbols-outlined">
                      package_2
                    </span>
          </div>
          
          <div className='sidebarButtonIcon'  onClick={() => {
                        navigate('/products')
                      }}>
          <span class="material-symbols-outlined">
                        receipt_long
                      </span>
          </div>
          <div className='sidebarButtonIcon'onClick={() => {
                        navigate('/tools')
                      }}>
            <span class="material-symbols-outlined">
                        build
                      </span>
          </div>
          <div className='sidebarButtonIcon' onClick={() => {
                        navigate('/customers')
                      }}>
            <span class="material-symbols-outlined">
                      manage_accounts
                    </span>
          </div>
          <div className='sidebarButtonIcon' onClick={() => {
                        navigate('/couponCode')
                      }}>
            <span class="material-symbols-outlined">
                      qr_code
                    </span>
          </div>
          <div className='sidebarButtonIcon' onClick={() => {
                        navigate('/settings')
                      }}>
             <span class="material-symbols-outlined">
                      settings
                    </span>
          </div>
        </div>
      </div>

      <div className='smallSidebarFooter'></div>
    </div>
  )
}

export default SmallSidebar
