import React from 'react'
import firebase from './firebase.config'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import OtpInput from 'react-otp-input'
import './SigninCombined.css'
import { redirect, useNavigate } from 'react-router-dom'
import { FaSpinner } from 'react-icons/fa'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { BASE_URL, cookieCreator, cookieFetcher } from '../../helper'
import CountDownButton from '../../components/CountDownButton/CountDownButton'
import Countdown from 'react-countdown'
import Swal from 'sweetalert2'
import { Helmet } from 'react-helmet'

class App extends React.Component {
  constructor(props) {
    super(props)

    // Redirect using JavaScript
    this.redirectUsingJS = this.redirectUsingJS.bind(this)
  }
  state = {
    showotp: false,
    mobile: '',
    otp: '',
    loadingNumber: false,
    loadingOtp: false,
    agree: true,
    warning: false,
    recaptcha: {},
  }

  CountdownComponent = ({ onSignInSubmit, state, thisCopy }) => {
    const CountdownRenderer = ({ hours, minutes, seconds, completed }) => {
      if (completed) {
        // Countdown is complete, display a message
        return (
          <div
            className='countDownButtonActive'
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              this.onSignInSubmit(e)
            }}
          >
            Resend OTP
          </div>
        )
      } else {
        return (
          <div className='countDownButtonInactive'>
            {String(seconds).padStart(2, '0')}s
          </div>
        )
      }
    }

    return (
      <div>
        <Countdown date={Date.now() + 20000} renderer={CountdownRenderer} />
      </div>
    )
  }

  componentDidMount() {
    const token = cookieFetcher('token')
    if (token) {
      document.location.href = '/'
    }
  }

  componentDidUpdate() {
    console.log('Printing the state', this.state.recaptcha)
  }

  redirectUsingJS() {
    // Use the history object to redirect
    const { history } = this.props
    history.push('/home')
  }

  checkPresence = async () => {
    const res = await fetch(
      `${BASE_URL}/vendor/check_number/+${this.state.mobile}`
    )
    const value = await res.json()
    if (value.message === 'Already Vendor Exits !!') {
      console.log('Vendor Exists!!')
      cookieCreator('token', value.token)
      cookieCreator('number', value.data.phone)
      cookieCreator('vendorId', value.data._id)
      return true
    }
    return false
  }

  configureCaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'sign-in-button',
      {
        size: 'invisible',
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          this.onSignInSubmit()
          console.log('Recaptca varified')
        },
        defaultCountry: 'IN',
      }
    )
    this.setState({ recaptcha: window.recaptchaVerifier })
    console.log('giving an output', window.recaptchaVerifier)
  }
  onSignInSubmit = (e) => {
    if (this.state.agree === false) {
      this.setState({ warning: true })
      return
    }
    if (typeof e === 'object') {
      e.preventDefault()
    }
    let phoneNumber = ''
    if (typeof e === 'string' && e.length == 12) {
      console.log(this.props)
      phoneNumber = '+' + e
    } else {
      phoneNumber = '+' + this.state.mobile
    }
    if (!window.recaptchaVerifier) {
      this.configureCaptcha()
    }
    console.log(phoneNumber)
    this.setState({ loadingNumber: true })
    const appVerifier = window.recaptchaVerifier
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult
        console.log('OTP has been sent')
        this.setState({ loadingNumber: false })
        this.setState({ showotp: true })
      })
      .catch((error) => {
        console.log(error)
        console.log('SMS not sent')
      })
  }
  onSubmitOTP = (e) => {
    this.setState({ loadingOtp: true })
    let code = ''
    if (typeof e === 'string') {
      code = e
    }
    if (typeof e === 'object') {
      e.preventDefault()
      code = this.state.otp
    }
    console.log(code)
    window.confirmationResult
      .confirm(code)
      .then((result) => {
        console.log('Printing the result', result.user.refreshToken)
        cookieCreator('refreshToken', result.user.refreshToken)
        const presenceFinder = async () => {
          const presence = await this.checkPresence()
          if (!presence) {
            window.location.href = '/signup'
          }
          if (presence) {
            window.location.href = '/home'
          }
        }
        presenceFinder()
      })
      .catch((error) => {
        console.log(error)
      })
  }
  render() {
    return (
      <div className='signInPage'>
        <Helmet
          title='Sign In'
          link={{
            rel: 'icon',
            type: 'image/png',
            href: cookieFetcher('photo'),
          }}
        ></Helmet>
        <div id='sign-in-button'></div>
        {this.state.showotp ? (
          <div className='otpPanel'>
            <div className='otpPanelComp'>
              <div className='OTPTitleText'>OTP Verification</div>
              <div className='OTPInstructionText'>
                Enter the verification code we sent to
              </div>
              <div className='OTPNumberText'>+{this.state.mobile}</div>
            </div>
            <div className='otpPanelComp'>
              <div className='OTPInputLabel'>
                Type your 6 digit security code
              </div>
              <form
                className='otpInputPanelwithButton'
                onSubmit={this.onSubmitOTP}
              >
                <OtpInput
                  containerStyle={{ display: 'flex', gap: '5px' }}
                  inputStyle={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '5px',
                    border: 'solid 1px #c4c2c2',
                  }}
                  value={this.state.otp}
                  onChange={(e) => {
                    this.setState({ otp: e })
                    if (e.length == 6) {
                      this.onSubmitOTP(e)
                    }
                  }}
                  numInputs={6}
                  renderSeparator={<span> </span>}
                  renderInput={(props) => <input {...props} />}
                />
                {!this.state.loadingOtp ? (
                  <div className='OTPSubmitAndResend'>
                    <div className='OTPSubmit' onClick={this.onSubmitOTP}>
                      Submit
                    </div>
                    <this.CountdownComponent></this.CountdownComponent>
                  </div>
                ) : (
                  <div className='OTPSubmitAndResend'>
                    <div
                      className='OTPSubmit'
                      style={{ background: '#79cdfc' }}
                    >
                      <FontAwesomeIcon icon={faSpinner} spin></FontAwesomeIcon>
                    </div>
                    <this.CountdownComponent></this.CountdownComponent>
                  </div>
                )}
              </form>
            </div>
            <div className='OTPNumberUpdate otpPanelComp'>
              {/* <div className='OTPNumberUpdate1 OTPNumberUpdateComp '>
                {' '}
                Didn’t get the code ?
                <span style={{ color: '#009ef7' }} onClick={() => {}}>
                  {' '}
                  Resend{' '}
                </span>
              </div> */}
              <div className='OTPNumberUpdate2 OTPNumberUpdateComp'>
                Wrong Number ?<span style={{ color: '#009ef7' }}> Edit </span>
              </div>
            </div>
          </div>
        ) : (
          <div className='signInPanel'>
            <div className='imageHolderForSignIn'>
              <img src='https://res.cloudinary.com/nikhil-patil-web/image/upload/v1689078419/SignIn_bvdsux.png'></img>
            </div>
            <div className='signInTitle'>Sign Up / Sign In</div>
            <div className='signInFormComp'>
              <form
                className='signInFormWithOTP'
                onSubmit={this.onSignInSubmit}
              >
                <div id='sign-in-button'></div>
                <PhoneInput
                  country={'in'}
                  name='mobile'
                  onChange={(e) => {
                    console.log(e, 'is of length', e.length)
                    this.setState({ mobile: e })
                    if (e.length === 12) {
                      this.onSignInSubmit(e)
                    }
                  }}
                ></PhoneInput>
                {!this.state.loadingNumber ? (
                  <div
                    className='verifyButton'
                    style={
                      this.state.agree
                        ? { background: '#009ef7' }
                        : { background: '#79cdfc' }
                    }
                    onClick={this.onSignInSubmit}
                  >
                    Verify
                  </div>
                ) : (
                  <div
                    className='verifyButton'
                    style={{ background: '#79cdfc' }}
                    onClick={this.onSignInSubmit}
                  >
                    <FontAwesomeIcon icon={faSpinner} spin />
                  </div>
                )}
              </form>
            </div>
            <div className='agreeButton'>
              <input
                type='checkBox'
                onClick={() => {
                  this.setState({ agree: !this.state.agree })
                }}
                checked={this.state.agree}
              ></input>
              <div>
                I Agree to the{' '}
                <span style={{ color: '#009ef7' }}> Terms & Conditions</span>{' '}
                and
                <span style={{ color: '#009ef7' }}> Privacy Policy</span>
              </div>
            </div>
            {this.state.warning && (
              <div style={{ color: 'red', fontSize: '12px', fontWeight: 500 }}>
                {' '}
                Please agree to the terms and conditions
              </div>
            )}
            <div
              style={{ fontSize: '12px', fontWeight: 500, color: '#6c757d' }}
            >
              By tapping Verify Phone Number a sms will be sent to this number.
              <br />
              Message & data rate may be applied.
            </div>
          </div>
        )}
      </div>
    )
  }
}
export default App
