import withNavigation from "../../withNavigation";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
//import { checkAuthed, handleDelete } from "../../../utils";
import { BsJustify, BsSearch } from "react-icons/bs";
import axios from "axios";
import { BASE_URL, cookieFetcher, loginChecker } from "../../helper";
//import { API_URL } from "../../../utils";
import _ from "lodash";
//import Switch from "@material-ui/core/Switch";
//import { APP_URL, VENDOR_PANEL_URL } from "../../../utils";
import { Link } from "react-router-dom";
import CryptoJS from "crypto-js";
import { Helmet } from "react-helmet";
import Sidebar from "../../components/Sidebar/Sidebar";
import OrdersHeader from "../Orders/OrdersHeader";
import Footer from "../../components/Footer/Footer";
import ProductsDropDown from "../../components/Dropdowns/ProductsDropDown";
import { utils, write, writeFile } from 'xlsx';
import { saveAs } from 'file-saver';

import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown,
} from "reactstrap";

// const API_URL = "https://betabackend.lofaz.in/api/";

const ViewProfileButton = ({
  viewUrl,
  handleEdit,
  handleDelete,
  vendorDetailsUrl,
  handleGuestLogin,
}) => (
  <div className="container">
    <div className="d-flex flex-row justify-center" style={{ gap: "7px" }}>
      <a
        className="btn btn-dark p-2"
        href={viewUrl}
        target="_blank"
        rel="noreferrer"
        title="View Store"
      >
        <span class="material-symbols-outlined font-bold">open_in_new</span>
      </a>

      <Link
        className="btn btn-dark p-2"
        to={vendorDetailsUrl}
        target="_blank"
        rel="noreferrer"
        title="View Details"
      >
        <i className="fas fa-eye" />
      </Link>
      <button
        className="btn btn-dark p-2"
        title="Guest Login"
        onClick={handleGuestLogin}
      >
        <i className="fas fa-sign-in-alt" />
      </button>
      <button
        className="btn btn-dark p-2"
        onClick={handleEdit}
        title="Add Note"
      >
        <i className="fas fa-sticky-note" />
      </button>
      <button className="btn btn-dark p-2" onClick={handleEdit} title="Edit">
        <i className="fas fa-pen" />
      </button>
      <button
        className="btn btn-danger p-2"
        onClick={handleDelete}
        title="Delete"
      >
        <i className="fas fa-trash" />
      </button>
    </div>
  </div>
);

export const getProductTableConfig = async (
  currentPage,
  limit,
  search = "",
  sort = "createdAt",
  sortBy = -1
) => {
  try {
    const response = await axios.get(`https://api.lofaz.com/api/customer/list`, {
      params: {
        page: currentPage,
        limit: limit,
        search: search,
        sort: sort,
        sortBy: sortBy,
      },
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDBmNDdlNDU5NDliZmFkYTVjZDNkNTEiLCJpYXQiOjE3MTk2NDQwOTIsImV4cCI6MTc1MTE4MDA5Mn0.U3U5r5pTbS_B-bSmWE9BGSAG2SLzcAeSL1Efqsv4pxg",
      },
      mode: "cors",
      cache: "default",
    });
    loginChecker()
    const data = response.data;

    console.log("Data fetched correctly", data);
    return data;
  } catch (error) {
    console.error("Error fetching orders:", error);
    return { data: [], total_shop: 0 };
  }
};

class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentPage: 1,
      limit: 10, // Initial rows per page
      totalRows: 0,
      fetched: false,
      search: "",
      sort: -1,
      sortBy: "createdAt",
      featuredOnly: false,
      sidebar: true,
      shouldRedirect: false,

      // searchBy: 'title', // Default searchBy field set to 'title' (Products Name)
    };

    this.debouncedFetchData = _.debounce(this.fetchData, 300);
  }

  async componentDidMount() {
    const { currentPage, limit, search, sort, sortBy } = this.state;
    this.setState({ currentPage, limit, search, sort, sortBy }, () => {
      this.fetchData(currentPage, limit, search, sort, sortBy);
    });
  }

  fetchData = async (
    currentPage,
    limit,
    search = "",
    sort = 1,
    sortBy = "createdAt"
  ) => {
    try {
      const data = await getProductTableConfig(
        currentPage,
        limit,
        search,
        sortBy,
        sort
      );
      console.log("fetch", data);
      if (data) {
        this.setState({
          data: this.state.featuredOnly
            ? data.data.filter(
              (item) => item.activate === this.state.featuredOnly
            )
            : data.data,
          totalRows: data.total_shop,
          fetched: true,
        });
      }
      console.log("data", this.state.data);
    } catch (error) {
      console.log("Error occurred while fetching orders:", error);
    }
  };

  updateURLParams = () => {
    const { currentPage, limit, search, sort, sortBy } = this.state;
    const queryParams = new URLSearchParams({
      page: currentPage,
      limit,
      search,
      sort,
      sortBy,
    }).toString();
    this.props.history.push(`?${queryParams}`);
  };
  //navigate = useNavigate();
  getURLParams = () => {
    const searchParams = new URLSearchParams(this.props.location.search);
    const currentPage = parseInt(searchParams.get("page")) || 1;
    const limit = parseInt(searchParams.get("limit")) || 10;
    const search = searchParams.get("search") || "";
    const sort = parseInt(searchParams.get("sortBy")) || -1;
    const sortBy = searchParams.get("sort") || "createdAt";

    return { currentPage, limit, search, sort, sortBy };
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      const { currentPage, limit, search, sort, sortBy } = this.state;
      console.log("on page change:", currentPage, limit, search, sort, sortBy);
      this.fetchData(currentPage, limit, search, sort, sortBy);
      //this.updateURLParams();
    });
  };

  handleRedirect = () => {
    this.setState({ shouldRedirect: true });
  };

  handleSearchChange = (e) => {
    const search = e.target.value;
    this.setState({ search }, () => {
      const { currentPage, limit, search, sort, sortBy } = this.state;
      console.log("fetch params", currentPage, limit, search, sort, sortBy);
      this.debouncedFetchData(currentPage, limit, search, sort, sortBy);
      //this.updateURLParams();
    });
  };

  toggleFeaturedFilter = () => {
    this.setState((prevState) => ({
      featuredOnly: !prevState.featuredOnly,
    }));
  };
  toggleSidebar = () => {
    this.setState((prevState) => ({
      sidebar: !prevState.sidebar,
    }));
  };

  // updateActivate = async (status, id) => {
  //   try {
  //     const response = await axios.patch(
  //       `${API_URL}vendor/activate/${id}`,
  //       { activate: status },
  //       {
  //         headers: {
  //           Authorization: "Bearer YOUR_AUTH_TOKEN_HERE", // Replace with dynamic token retrieval
  //         },
  //       }
  //     );

  //     //console.log(response.data); // Log the response data for debugging purposes
  //     const { currentPage, limit, search, sort, sortBy } = this.state;
  //     this.fetchData(currentPage, limit, search, sort, sortBy);
  //   } catch (err) {
  //     console.error("Error updating activation status:", err);
  //     // Handle the error appropriately, maybe show a notification to the user
  //   }
  // };

  handleRowsPerPageChange = (newPerPage) => {
    this.setState({ currentPage: 1, limit: newPerPage }, () => {
      const { currentPage, limit, search, sort, sortBy } = this.state;
      this.fetchData(currentPage, limit, search, sort, sortBy);
      //this.updateURLParams();
    });
  };

  handleSortClick = (column) => {
    //console.log("Sorting by", column);

    this.setState(
      (prevState) => {
        const newSort = prevState.sort === column ? prevState.sortBy * -1 : 1;
        return { sort: column, sortBy: newSort, currentPage: 1 };
      },
      () => {
        console.log(
          `Sorting by ${this.state.sort} in order ${this.state.sortBy}`
        );
        this.fetchData(
          this.state.currentPage,
          this.state.limit,
          this.state.search,
          this.state.sortBy,
          this.state.sort
        );
        //this.updateURLParams();
      }
    );
  };

  downloadAsXls = () => {
    const { data } = this.state;
    const columns = this.columns.map(col => ({ name: col.name, selector: col.selector }));
    const tableName = 'CustomerData'; // Customize this as needed
    const wb = utils.book_new();
    wb.Props = {
      Title: tableName,
      Subject: "Exported Data",
      Author: "Your Company",
      CreatedDate: new Date(),
    };
    wb.SheetNames.push("Sheet1");
    // Prepare header
    const header = columns.map(col => col.name);
    const ws_data = [header];
    // Prepare data rows
    data.forEach(row => {
      const rowData = columns.map(col => row[col.selector] || ''); // Adjust based on your data structure
      ws_data.push(rowData);
    });
    const ws = utils.aoa_to_sheet(ws_data);
    wb.Sheets["Sheet1"] = ws;
    // Convert to binary string and save
    const wbout = write(wb, { bookType: "xlsx", type: "binary" });
    const s2ab = s => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    };
    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      `${tableName}_${new Date().toISOString().slice(0, 10)}.xlsx`
    );
  };
  

  columns = [
    {
      name: "#",
      cell: (row, index) => (
        <p className="text-center">
          {(this.state.currentPage - 1) * this.state.limit + index + 1}
        </p>
      ),
      width: "100px",
    },

    {
      name: "CUSTOMER NAME",
      cell: (data) => {
        //console.log(data);
        return <p className="font-semibold text-center">{data && data.customer_id ? data.customer_id.full_name : ""}</p>;
      },
      width: "250px",
      sortField: "customer_id.full_name",
      sortable: true,
    },
    {
      name: "PHONE NUMBER",
      cell: (data) => {
        //console.log(data);
        return <p className="font-semibold text-center">{data && data.customer_id ? data.customer_id.phone : ""}</p>;
      },
      width: "250px",
      sortField: "phone",
      sortable: true,
    },
    {
      name: "TOTAL ORDERS",
      cell: (data) => (<div>
        {/* Conditionally render MRP if it exists */}
        {data.mrp && (
          <p className="line-through text-gray-500 font-semibold mb-0">
            {data.mrp}
          </p>
        )}
        {/* Display sales price */}
        <p className="mb-0 font-semibold">
          {data.sales_price ? data.sales_price : ""}
        </p>
      </div>),
      sortField: "sales_price",
      sortable: true,
    },
    {
      name: "TOTAL AMOUNT",
      cell: (data) => {
        //console.log(data);
        return <p className="font-semibold text-center">{data.status ? '' : ''}</p>;
      },
      sortField: "status",
      sortable: true,
    },
    {
      name: "LAST ORDER DATE",
      selector: (row) => {
        const date = new Date(row ? row.updatedAt : date.Now());
        const options = {
          weekday: "short",
          month: "short",
          day: "2-digit",
          year: "numeric",
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        };
        const formattedDate = date
          .toLocaleString("en-US", options)
          .replace(",", "");
        return <p className="font-semibold">{formattedDate}</p>;
      },
      //onClick: () => this.handleSortClick('createdAt'),
      sortField: "updatedAt",
      sortable: true,
      width: '250px'
      // sortFunction: {this.handleSortClick('createdAt')}}
    },
   
    // {
    //   name: "ACTIONS",
    //   cell: (row) => (
    //     <ProductsDropDown
    //       product={row} // Pass the product data
    //       fetchStatus={this.state.fetched} // or another relevant state
    //       setFetchStatus={(status) => this.setState({ fetched: status })}
    //     />
    //   ),
    // }
    // {
    //   name: "STATUS",
    //   selector: (row) => {
    //     const date = new Date(row.updatedAt);
    //     const options = {
    //       weekday: "short",
    //       month: "short",
    //       day: "2-digit",
    //       year: "numeric",
    //       hour: "numeric",
    //       minute: "2-digit",
    //       hour12: true,
    //     };
    //     const formattedDate = date
    //       .toLocaleString("en-US", options)
    //       .replace(",", "");
    //     return formattedDate;
    //   },
    //   minWidth: "200px",
    //   //onClick: () => this.handleSortClick('createdAt'),
    //   sortField: "updatedAt",
    //   sortable: true,
    //   // sortFunction: {this.handleSortClick('createdAt')}}
    //   minWidth: "200px",
    // },
    // {
    //   name: "View Profile",
    //   cell: (row) => (
    //     <ViewProfileButton
    //       // viewUrl={APP_URL + row.username}
    //       vendorDetailsUrl={`/vendor/${row._id}`}
    //       handleDelete={() => handleDelete(row._id, "Vendor")}
    //       handleGuestLogin={() => {
    //         const date = new Date();
    //         const url =
    //           VENDOR_PANEL_URL +
    //           "login?guest=" +
    //           encodeURIComponent(
    //             CryptoJS.AES.encrypt(
    //               row && row.phone,
    //               process.env.REACT_APP_GUEST_SECRET +
    //                 String(Number(date.getSeconds()) > 30 ? 0 : 1) +
    //                 date.getMinutes() +
    //                 date.getHours() +
    //                 date.getDay() +
    //                 date.getMonth() +
    //                 date.getFullYear()
    //             ).toString()
    //           );
    //         window.open(url, "_blank");
    //       }}
    //     />
    //   ),
    //   minWidth: "300px",
    // },
    // {
    //   name: "Actions",
    //   cell: (row) => {
    //     const handleToggle = () => {
    //       const newActivateState = !row?.activate;
    //       this.updateActivate(newActivateState, row?._id);
    //     };
    //     //not using handleToggle since featured bhi toggle hota dono ka same API field hai
    //     return (
    //       <div>
    //         <Toggle
    //           defaultChecked={true}
    //           color="primary"
    //           name="activate-switch"
    //           inputProps={{ "aria-label": "activate switch" }}
    //         />
    //         {/* {row?.activate ? <span>Inactive</span> : <span>Active</span>} */}
    //       </div>
    //     );
    //   },
    //   minWidth: "200px",
    // },
  ];

  // handleStatusChange = async (id, newStatus) => {
  //   // Make API call to update the status on the server
  //   try {
  //     await axios.patch(`${API_URL}catalog/${id}`, { active: newStatus }, {
  //       headers: authHeader(),
  //     });
  //     const updatedData = this.state.data.map(item =>
  //       item._id === id ? { ...item, active: newStatus } : item
  //     );
  //     this.setState({ data: updatedData });
  //   } catch (error) {
  //     console.error("Error updating catalog status:", error);
  //   }
  // };
  customStyles = {
    headCells: {
      style: {
        display: 'flex',
        justifyContent: 'center', // Center align header text horizontally
        alignItems: 'center',     // Center align header text vertically
        textAlign: 'center',      // Ensure text is centered
        //fontWeight: 'bold',       // Optional: Make header text bold
        padding: '0',             // Optional: Remove padding if necessary
      },
    },
    cells: {
      style: {
        display: 'flex',
        justifyContent: 'center', // Center align header text horizontally
        alignItems: 'center',     // Center align header text vertically
        textAlign: 'center',
      },
    },
  };

  render() {
    const { data, fetched, currentPage, search, limit, totalRows } = this.state;
    const { history } = this.props;
    // // Apply the filtering here based on featuredOnly state
    // const filteredData = this.state.featuredOnly ? data.filter(item => item.activate===this.state.featuredOnly) : data;
    // console.log("filter",filteredData);
    console.log("order", data);

    // Conditional rendering based on whether data has been fetched
    if (!fetched) {
      return (
        <>
          {/* Replace with your actual loading component */}
          <div class="d-flex align-items-center justify-content-center vh-100">
            <div class="spinner-border text-body" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="productsPage d-flex">
          <Helmet
            title="Customers"
            link={{
              rel: "icon",
              type: "image/png",
              href: cookieFetcher("photo"),
            }}
          ></Helmet>
          <Sidebar
            sidebar={this.state.sidebar}
            page={"customer"}
            product={true}
          ></Sidebar>
          <div className="productsMain" style={{ width: "100vw" }}>
            <OrdersHeader
              sidebar={this.state.sidebar}
              setSidebar={this.toggleSidebar}
            ></OrdersHeader>
            <div className="productsMainTop">
              <div className="productsMainSec">
                <div className="productsMainSecButton d-flex justify-content-between">
                  <div className="onTopLeft d-flex align-items-center justify-content-center m-4">
                    <div
                      className="onSearch d-flex align-items-center"
                      style={{ gap: "5px" }}
                    >
                      <div className="searchIcon">
                        <BsSearch size={25}></BsSearch>
                      </div>
                      <div className="searchText">
                        <input
                          type="text"
                          className="form-control"
                          aria-label="select search by"
                          placeholder="Search Customer"
                          value={search}
                          onChange={this.handleSearchChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="onTopRight d-flex align-items-center justify-content-center m-4"
                    style={{ gap: "5px" }}
                  >
                    <button
                      className="btn btn-secondary d-flex align-items-center"
                      style={{
                        border: "none",
                        color: "white",
                      }}
                      onClick={this.downloadAsXls}
                    >
                      <span class="material-symbols-outlined">download</span>
                      <span>Export</span>
                    </button>
                    <UncontrolledDropdown>
                      <DropdownToggle className="filetrBtn d-flex align-items-center btn">
                        {/* <TiFilter
                      style={{
                        marginTop: '0px',
                        background: 'rgba(0,0,0,0)',
                        color: 'white',
                        fontSize: '23px',
                        
                        
                      }}
                      
                    ></TiFilter> */}
                        <span class="material-symbols-outlined">
                          filter_alt
                        </span>
                        <span>Filter</span>
                      </DropdownToggle>
                      <DropdownMenu className="filter_dropdown">
                        <DropdownItem
                          onClick={() => {
                            this.setState(
                              {
                                // dateSorted: true,
                                // nameSorted: false,
                                // priceSorted: false,
                                // statusSorted: false,
                                sortBy: 1,
                                sort: "updatedAt",
                              },
                              () =>
                                this.fetchData(
                                  this.state.currentPage,
                                  this.state.limit,
                                  this.state.search,
                                  this.state.sortBy,
                                  this.state.sort
                                )
                            );
                          }}
                        >
                          Sort By Date
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            this.setState(
                              {
                                // dateSorted: false,
                                // nameSorted: true,
                                // priceSorted: false,
                                // statusSorted: false,
                                sortBy: 1,
                                sort: "full_name",
                              },
                              () =>
                                this.fetchData(
                                  this.state.currentPage,
                                  this.state.limit,
                                  this.state.search,
                                  this.state.sortBy,
                                  this.state.sort
                                )
                            );
                          }}
                        >
                          Sort By Name
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            this.setState(
                              {
                                // dateSorted: true,
                                // nameSorted: false,
                                // priceSorted: false,
                                // statusSorted: false,
                                sortBy: 1,
                                sort: "sales_price",
                              },
                              () =>
                                this.fetchData(
                                  this.state.currentPage,
                                  this.state.limit,
                                  this.state.search,
                                  this.state.sortBy,
                                  this.state.sort
                                )
                            );
                          }}
                        >
                          Sort By Orders
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            this.setState(
                              {
                                // dateSorted: true,
                                // nameSorted: false,
                                // priceSorted: false,
                                // statusSorted: false,
                                sortBy: 1,
                                sort: "status",
                              },
                              () =>
                                this.fetchData(
                                  this.state.currentPage,
                                  this.state.limit,
                                  this.state.search,
                                  this.state.sortBy,
                                  this.state.sort
                                )
                            );
                          }}
                        >
                          Sort By Amount
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            this.setState(
                              {
                                // dateSorted: true,
                                // nameSorted: false,
                                // priceSorted: false,
                                // statusSorted: false,
                                sortBy: 1,
                                sort: "phone",
                              },
                              () =>
                                this.fetchData(
                                  this.state.currentPage,
                                  this.state.limit,
                                  this.state.search,
                                  this.state.sortBy,
                                  this.state.sort
                                )
                            );
                          }}
                        >
                          Sort By Mobile
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    {/* <div
                      className="addProductButton btn"
                      style={{
                        background: "#009ef7",
                        border: "none",
                        // paddingLeft: "20px",
                        // paddingRight: "20px",
                        // paddingTop: "10px",
                        // paddingBottom: "10px",
                        color: "white",
                        fontWeight: "600",
                        fontSize: "14px",
                        //borderRadius: "10px",
                      }}
                    >
                      <button
                        style={{
                          background: "#009ef7",
                          border: "none",
                          color: "white",
                        }}
                        onClick={() => this.props.handleRedirect("/addProduct")}
                      >
                        Add Customer
                      </button>
                    </div> */}
                  </div>
                </div>

                <DataTable
                  title=""
                  {...getProductTableConfig(history)}
                  data={data}
                  columns={this.columns}
                  pagination
                  paginationServer
                  paginationPerPage={limit} // Make sure this matches your 'limit' state
                  paginationRowsPerPageOptions={[10, 15, 20, 30, 50, 100]} // Options for rows per page
                  paginationTotalRows={totalRows} // Total rows count
                  onChangePage={this.handlePageChange}
                  onChangeRowsPerPage={this.handleRowsPerPageChange}
                  sortServer // Indicate that sorting should be handled on the server side
                  onSort={(column, direction) =>
                    this.handleSortClick(column.sortField)
                  }
                  customStyles={this.customStyles}
                  // previousLabel={"< Previous"}
                  // nextLabel={"Next >"}
                />
              </div>
            </div>

            <Footer></Footer>
          </div>
        </div>
      </>
    );
  }
}

export default withNavigation(Customer);
