import logo from './logo.svg'
import './App.css'
import { Switch, Route, Routes } from 'react-router-dom'
import Orders from './pages/Orders/Orders'
import Home from './pages/Home/Home'
import CouponCode from './pages/CouponCode/CouponCode'
import Customer from './pages/Customer/Customer'
import Profile from './pages/Profile/Profile'
import Setting from './pages/Setting/Setting'
import Products from './pages/Products/Products'
import Catalog from './pages/Catalog/Catalog'
import AddProduct from './pages/AddProduct/AddProduct'
import SignIn from './components/SignIn/SignIn'
import OTP from './components/OTP/OTP'
import SignInCombined from './pages/SignInCombined/SignInCombined'
import AddCatalog from './pages/AddCatalog/AddCatalog'
import EditCatalog from './pages/EditCatalog/EditCatalog'
import EditProduct from './pages/EditProduct/EditProduct'
import CustomCollapse from './components/Collapse/Collapse'
import StoreStatusSetting from './pages/Setting/StoreStatusSetting'
import PrivacyPolicySetting from './pages/Setting/PrivacyPolicySetting'
import ShippingAndDeliverySettings from './pages/Setting/ShippingandDeliverySetting'
import SocialMediaSettings from './pages/Setting/SocialMediaSettings'
import StoreTimingSettings from './pages/Setting/StoreTimingSettings'
import OrderDetails from './pages/OrderDetails/OrderDetails'
import SignUp from './pages/SignUp/SignUp'
import Manage from './pages/Manage/Manage'
import QrCode from './pages/Manage/QrCode'
import { Helmet } from 'react-helmet'
import { useEffect } from 'react'
import { BASE_URL, cookieCreator, cookieFetcher, defaultLogo } from './helper'
import { useState } from 'react'
import Homebanner from './pages/Home/Homebanner'
import Slider from './pages/Home/Bannercomp/Slider'

function App() {
  const [image, setImage] = useState(defaultLogo)
  useEffect(() => {
    const fetcher = async () => {
      const response = await fetch(
        `${BASE_URL}/vendor/visitor/${cookieFetcher('username')}`
      )
      const res = await response.json()
      if (res.data) {
        cookieCreator('photo', res.data.photo)
      } else {
        console.log(res)
      }
    }
    fetcher()
  }, [])
  return (
    <div className='App'>
      <Routes>
        <Route path='/' Component={Home} />
        <Route path='/home' Component={Home} />
        <Route path='/orders' Component={Orders} />
        <Route path='/orders/:orderId' Component={OrderDetails}></Route>
        <Route path='/customers' Component={Customer}></Route>
        <Route path='/couponCode' Component={CouponCode}></Route>
        <Route path='/settings' Component={Setting}></Route>
        <Route
          path='/settings/storeStatus'
          Component={StoreStatusSetting}
        ></Route>
        <Route
          path='/settings/privacyPolicy'
          Component={PrivacyPolicySetting}
        ></Route>
        <Route
          path='/settings/shippingAndDelivery'
          Component={ShippingAndDeliverySettings}
        ></Route>
        <Route
          path='/settings/socialMedia'
          Component={SocialMediaSettings}
        ></Route>
        <Route path='/settings/timings' Component={StoreTimingSettings}></Route>
        <Route path='/profile' Component={Profile}></Route>
        <Route path='/products' Component={Products}></Route>
        <Route path='/catalog/edit/:id' Component={EditCatalog}></Route>
        <Route path='/category' Component={Catalog}></Route>
        <Route path='/addProduct' Component={AddProduct}></Route>
        <Route path='/addCatalog' Component={AddCatalog}></Route>
        <Route path='/products/edit/:id' Component={EditProduct}></Route>
        {/* <Route path='/signIn' Component={SignIn}></Route> */}
        {/* <Route path='/otp' Component={OTP}></Route> */}
        <Route path='/signin' Component={SignInCombined}></Route>
        <Route path='/collapse' Component={CustomCollapse}></Route>
        <Route path='/signup' Component={SignUp}></Route>
        <Route path='/tools' Component={Manage}></Route>
        <Route path='/tools/qrCode' Component={QrCode}></Route>
        <Route path='/tools/slider' Component={Slider}></Route>
        <Route path='/banner' Component={Homebanner} ></Route>
      </Routes>
    </div>
  )
}

export default App
