import React from 'react'
import '../NavigationBox/NavigationBox.css'
import { BsChevronRight } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { editCatalog } from '../EditCatalogForm/CatalogEditor'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { createCatalog } from '../AddCatalogForm/CatalogCreator'
import { createProduct } from '../AddProductForm/CreateProduct'
import { productEditor } from '../EditProductForm/ProductEditor'
import Warning from '../Warning/Warning'

function NavigationBox({
  type,
  edit,
  id,
  catalogStatus,
  productStatus,
  setProductStatus,
  setCatalogStatus,
  editCatalogImages,
  editCatalogName,
  editCatalogFeatureImage,
  addCatalogFeatureImage,
  addCatalogImages,
  addCatalogName,
  addProductName,
  addProductImages,
  addProductFeatureImage,
  addProductCategory,
  addProductUnit,
  addProductSalesPrice,
  addProductMrp,
  addProductDescription,
  addProductStock,
  addProductLabel,
  setNameWarning,
  setPriceWarning,
  setMrpAndPriceWarning,
  nameWarning,
  priceWarning,
  mrpAndPriceWarning,
  catalogSeoTitle,
  catalogSeoDesc,
  catalogSeoTags,
}) {
  const options2 = [
    { value: true, label: `Active` },
    { value: false, label: 'Inactive' },
  ]

  const customStyles = {
    control: (base, state) => ({
      ...base,
      color: '#5e6278',
      fontWeight: 500,
      // paddingTop: '5px',
      paddingBottom: '35px',
      paddingLeft: '10px',
      borderRadius: '5px',
      height: '30px',
      width: '200px',
      minHeight: '30px',
      background: 'white',
      fontFamily: 'Poppins", sans-serif',
      fontSize: '15px',
      lineHeight: '21px',
      textAlign: 'left',
      border: state.isFocused
        ? '1px solid rgb(204, 204, 204)'
        : '1px solid rgb(204, 204, 204)',
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      '&:hover': {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? null : null,
      },
    }),

    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? '#f9f9f9' : 'white',
      color: state.isSelected ? '#009ef7' : '#5e6278',
      fontFamily: 'Poppins", sans-serif',
      fontSize: '13px',
      width: '170px',
      textAlign: 'left',
      borderRadius: '7px',
      padding: '15px',
      margin: '5px',
      '&:hover': {
        backgroundColor: '#f9f9f9',
        color: '#009ef7',
        cursor: 'pointer',
      },
    }),
    menu: (base, state) => ({
      ...base,
      width: '180px',
    }),
  }

  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const componentDidMount = () => {
    const urlHash = window.location.hash
    if (urlHash.length) {
      const element = document.getElementById(urlHash.substring(1))
      if (element) {
        element.scrollIntoView()
      }
    }
  }
  if (type === 'product') {
    return (
      <div className='navigationBox'>
        <div className='navigationBoxTop'>
          <div className='navBoxTitle' style={{ textAlign: 'left' }}>
            Navigation
          </div>
          <div className='navigationList'>
            <div
              className='navigationListEle'
              onClick={() => {
                navigate('/addProduct/#productMedia')
                componentDidMount()
              }}
            >
              {/* <BsChevronRight size={15}></BsChevronRight> */}
              <div className='elementTitle'>Media</div>
            </div>
            <div
              className='navigationListEle'
              onClick={() => {
                navigate('/addProduct/#productInfo')
                componentDidMount()
              }}
            >
              {/* <BsChevronRight size={15}></BsChevronRight> */}
              <div className='elementTitle'>Information</div>
            </div>
            <div
              className='navigationListEle'
              onClick={() => {
                navigate('/addProduct/#inventory')
                componentDidMount()
              }}
            >
              {/* <BsChevronRight size={15}></BsChevronRight> */}
              <div className='elementTitle'>Inventory</div>
            </div>
            <div
              className='navigationListEle'
              onClick={() => {
                navigate('/addProduct/#seo')
                componentDidMount()
              }}
            >
              {/* <BsChevronRight size={15}></BsChevronRight> */}
              <div className='elementTitle'>SEO</div>
            </div>
          </div>
        </div>

        <div
          className='navigationBoxBottom'
          style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
        >
          <div className='navBoxTitle' style={{ textAlign: 'left' }}>
            Status
          </div>
          <div className='statusDropDown'>
            <Select
              options={options2}
              placeholder={productStatus ? 'Active' : 'Inactive'}
              styles={customStyles}
              components={{
                IndicatorSeparator: () => null,
              }}
              menuPlacement='top'
              onChange={(e) => {
                setProductStatus(e.value)
              }}
            ></Select>
          </div>
          <div className='statusInstruction'>Set the product status.</div>
        </div>

        <div
          style={{
            marginTop: '',
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            alignItems: '',
            justifyContent: 'center',
            padding: '10px',
            background: 'white',
            borderRadius: '7px',
          }}
        >
          <div style={{ display: 'flex', gap: '5px' }}>
            <div
              onClick={() => {
                window.location.href = '/products'
              }}
              style={{
                width: '50%',
                cursor: 'pointer',
                background: '#7e8299',
                borderRadius: '7px',
                paddingTop: '5px',
                paddingBottom: '5px',
                color: 'white',
                fontSize: '12px',
                fontWeight: '600',
              }}
            >
              CANCEL
            </div>
            <div
              className='addProductNavigation'
              style={{
                width: '50%',
                cursor: 'pointer',
                background: '#009ef7',
                borderRadius: '7px',
                paddingTop: '5px',
                paddingBottom: '5px',
                color: 'white',
                fontSize: '12px',
                fontWeight: '600',
              }}
              onClick={() => {
                if (!edit) {
                  setLoading(true)
                  createProduct(
                    addProductFeatureImage,
                    addProductImages,
                    addProductCategory,
                    addProductName,
                    productStatus,
                    addProductUnit,
                    addProductSalesPrice,
                    addProductMrp,
                    addProductDescription,
                    addProductStock,
                    addProductLabel,
                    setNameWarning,
                    setPriceWarning,
                    setLoading,
                    setMrpAndPriceWarning
                  )
                }
                if (edit) {
                  setLoading(true)
                  productEditor(
                    addProductFeatureImage,
                    addProductImages,
                    addProductCategory,
                    addProductName,
                    productStatus,
                    addProductUnit,
                    addProductSalesPrice,
                    addProductMrp,
                    addProductDescription,
                    addProductStock,
                    addProductLabel,
                    id,
                    setNameWarning,
                    setPriceWarning,
                    setLoading,
                    setMrpAndPriceWarning
                  )
                }
              }}
            >
              {!loading && edit ? (
                'EDIT PRODUCT'
              ) : !loading && !edit ? (
                'SAVE'
              ) : (
                <FontAwesomeIcon icon={faSpinner} spin></FontAwesomeIcon>
              )}
            </div>
          </div>
          {(nameWarning || priceWarning || mrpAndPriceWarning) && (
            <Warning></Warning>
          )}
        </div>
      </div>
    )
  } else if (type === 'catalog') {
    return (
      <div className='navigationBox'>
        <div className='navigationBoxTop'>
          <div className='navBoxTitle' style={{ textAlign: 'left' }}>
            Navigation
          </div>
          <div className='navigationList'>
            <div
              className='navigationListEle'
              onClick={() => {
                if (edit && id) {
                  navigate(`/catalog/edit/${id}/#productMedia`)
                } else {
                  navigate('/addCatalog/#productMedia')
                }
                componentDidMount()
              }}
            >
              {/* <BsChevronRight size={15}></BsChevronRight> */}
              <div className='elementTitle'>Catalog Media</div>
            </div>
            <div
              className='navigationListEle'
              onClick={() => {
                if (edit && id) {
                  navigate(`/catalog/edit/${id}/#productInfo`)
                } else {
                  navigate('/addCatalog/#productInfo')
                }
                componentDidMount()
              }}
            >
              {/* <BsChevronRight size={15}></BsChevronRight> */}
              <div className='elementTitle'>Catalog Information</div>
            </div>
            <div
              className='navigationListEle'
              onClick={() => {
                if (edit && id) {
                  navigate(`/catalog/edit/${id}/#seo`)
                } else {
                  navigate('/addCatalog/#seo')
                }

                componentDidMount()
              }}
            >
              {/* <BsChevronRight size={15}></BsChevronRight> */}
              <div className='elementTitle'>SEO</div>
            </div>
          </div>
        </div>

        <div
          className='navigationBoxBottom'
          style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
        >
          <div className='navBoxTitle' style={{ textAlign: 'left' }}>
            Status
          </div>
          <div className='statusDropDown'>
            <Select
              options={options2}
              placeholder={edit && id && catalogStatus ? 'Active' : 'Inactive'}
              styles={customStyles}
              onChange={(e) => {
                console.log(e)
                setCatalogStatus(e.value)
              }}
              components={{
                IndicatorSeparator: () => null,
              }}
              menuPlacement='top'
            ></Select>
          </div>
          <div className='statusInstruction'>Set the category status.</div>
        </div>
        <div
          className=''
          style={{
            background: 'white',
            display: 'flex',
            justifyContent: 'center',
            gap: '8px',
            borderRadius: '5px',
            paddingTop: '10px',
            paddingBottom: '10px',
          }}
        >
          <div
            style={{
              width: '40%',
              background: '#7e8299',
              color: 'white',
              borderRadius: '7px',
              padding: '5px',
              fontSize: '12px',
              fontWeight: '600',
            }}
          >
            CANCEL
          </div>
          {!loading ? (
            <div
              onClick={() => {
                setLoading(true)
                if (!edit) {
                  createCatalog(
                    addCatalogFeatureImage,
                    addCatalogImages,
                    addCatalogName,
                    catalogStatus,
                    catalogSeoTitle,
                    catalogSeoDesc,
                    catalogSeoTags
                  )
                } else {
                  editCatalog(
                    editCatalogFeatureImage,
                    editCatalogImages,
                    editCatalogName,
                    catalogStatus,
                    id
                  )
                }
              }}
              style={{
                width: '40%',
                background: '#009ef7',
                color: 'white',
                borderRadius: '7px',
                padding: '5px',
                cursor: 'pointer',
                fontSize: '12px',
                fontWeight: '600',
              }}
            >
              {edit && id ? 'UPDATE CATALOG' : 'SAVE'}
            </div>
          ) : (
            <div
              style={{
                width: '40%',
                background: '#60c5ff',
                color: 'white',
                borderRadius: '7px',
                padding: '5px',
                fontSize: '12px',
                fontWeight: '600',
              }}
            >
              <FontAwesomeIcon icon={faSpinner} spin></FontAwesomeIcon>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default NavigationBox
