import Swal from 'sweetalert2'
import { BASE_URL, cookieFetcher } from '../../helper'
import { redirect } from 'react-router-dom'

export const editCatalog = async (
  featureImage,
  arrayImages,
  catalogName,
  catalogStatus,
  id
) => {
  const formdata = new FormData()

  let imageArray = []

  console.log(featureImage)
  if (featureImage.name) {
    formdata.append('photo', featureImage)
    console.log('Pushing due to feature image')
    imageArray.push({})
  } else if (featureImage.location) {
    console.log('Pushing due to feature image')
    imageArray.push(featureImage)
  } else if (featureImage === null) {
    console.log('Pushing due to feature image')
    imageArray.push(null)
  }
  console.log(arrayImages)

  arrayImages.map((item, index) => {
    if (item === null) {
      console.log('Pushing due to null', index)
      imageArray.push(null)
    } else if (item.name) {
      console.log('Pushing due to name', index)
      console.log(item.name)
      formdata.append('photo', item)
      imageArray.push({})
    } else if (item.location) {
      console.log('Pushing due to location', index)
      imageArray.push(item)
    }
  })
  if (imageArray.length < 5) {
    while (imageArray.length < 5) {
      imageArray.push(null)
    }
  }
  console.log(imageArray)
  formdata.append('title', catalogName)
  formdata.append('status', catalogStatus)
  formdata.append('photo', JSON.stringify(imageArray))
  const response = await fetch(`${BASE_URL}/vendor/catalog/${id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${cookieFetcher('token')}`,
    },
    body: formdata, // Use the FormData object as the request body
  })
  const data = await response.json()
  if (data.status === 200) {
    Swal.fire({
      icon: 'success',
      title: 'Catalog Edited',
      text: `Successfully Edited!`,
    })
    document.location.href = '/catalog'
  } else {
    Swal.fire({
      icon: 'fail',
      title: 'Failed try again',
      text: `${data.statusCode} : ${data?.message}`,
    })
  }
}
