import React, { useEffect } from "react";
import OrdersHeader from "../Orders/OrdersHeader";
import { useState } from "react";
import Footer from "../../components/Footer/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faCreditCard,
  faEnvelope,
  faMobile,
  faTruck,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { BASE_URL, cookieFetcher } from "../../helper";
import Swal from "sweetalert2";
import { Table } from "reactstrap";

function formatDateToDDMMYYYY(createdAtC) {
  const createdAt = new Date(createdAtC);
  const day = String(createdAt.getDate()).padStart(2, "0");
  const month = String(createdAt.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = String(createdAt.getFullYear());

  return `${day}/${month}/${year}`;
}

function OrderDetailsMain({ sidebar, setSidebar, order, subtotal }) {
  const [customer, setCustomer] = useState();

  useEffect(() => {
    // Check if order and order.shipping_details are defined
    if (!order || !order.shipping_details || !order.shipping_details.cust_id) {
      return; // Exit early if cust_id is undefined
    }

    // Convert cust_id to string if needed
    let OrderDetail = `${order.shipping_details.cust_id}`.toString();

    const fetcher = async () => {
      console.log(OrderDetail, "orderdetails");
      try {
        console.log(`${order.shipping_details.cust_id}`);
        const response = await fetch(
          `${BASE_URL}/customer/orders_details/${order.shipping_details._id}`,
          {
            headers: {
              Authorization: `Bearer ${cookieFetcher("token")}`,
            },
            method: "GET",
          }
        );
        const res = await response.json();
        console.log(res);
        if (res.status === 200) {
          setCustomer(res.data);
        } else {
          // Handle error case
          Swal.fire({
            icon: "error",
            title: "Failed!",
            text: `${res.status}: ${res.message}`,
          });
        }
      } catch (error) {
        console.error("Fetch error:", error);
        // Handle fetch error here
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: "Error fetching data. Please try again later.",
        });
      }
    };

    fetcher();
  }, [order]);
  console.log(`Name   ${customer && customer.full_name}`);
  console.log(`phone   ${customer && customer.phone}`);
  return (
    <div className="orderDetailsMain">
      <div className="orderDetailsMainTop">
        <OrdersHeader sidebar={sidebar} setSidebar={setSidebar}></OrdersHeader>
        <div className="ordersMainSec">
          <div className="ordersMainSecComponent ordersMainSecComponent1">
            <div className="leftComponent squareComponent">
              <div className="squareComponentTitle">
                Order Details (#{order && order.shipping_details.order_no})
              </div>
              <div className="squareComponentContent">
                <div className="listItem listItem1">
                  <div className="listItemLeft">
                    <FontAwesomeIcon icon={faCalendarDays} />
                    Date Added
                  </div>
                  <div className="listItemRight">
                    {order &&
                      formatDateToDDMMYYYY(order.shipping_details.createdAt)}
                  </div>
                </div>
                <div className="listItem listItem2">
                  <div className="listItemLeft">
                    <FontAwesomeIcon icon={faEnvelope} />
                    Invoice ID
                  </div>
                  <div className="listItemRight">
                    {order && order.shipping_details.order_no}
                  </div>
                </div>
                <div className="listItem listItem3">
                  <div className="listItemLeft">
                    <FontAwesomeIcon icon={faTruck} />
                    Order Status
                  </div>
                  <div className="listItemRight">
                    {order &&
                      order.shipping_details.accept_status &&
                      "Accepted"}
                    {order && order.shipping_details.cancel_status && "Cancel"}
                    {order &&
                      order.shipping_details.deliver_status &&
                      "Delivered"}
                    {order &&
                      order.shipping_details.pending_status &&
                      "Pending"}
                    {order &&
                      order.shipping_details.shipped_status &&
                      "Shipped"}
                  </div>
                </div>
              </div>
            </div>
            <div className="rightComponent squareComponent">
              <div className="squareComponentTitle">Customer Details</div>
              <div className="squareComponentContent">
                <div className="listItem">
                  <div className="listItemLeft">
                    <FontAwesomeIcon icon={faUser} />
                    Customer
                  </div>
                  <div
                    style={{ display: "flex", gap: "10px" }}
                    className="listItemRight"
                  >
                    {customer && customer.photo ? (
                      <img
                        src={customer.photo}
                        width="30px"
                        height="30px"
                      ></img>
                    ) : (
                      <FontAwesomeIcon
                        style={{ marginTop: "3px" }}
                        icon={faUser}
                      ></FontAwesomeIcon>
                    )}
                    {customer && order.shipping_details.shipping_name}
                  </div>
                </div>
                <div className="listItem">
                  <div className="listItemLeft">
                    <FontAwesomeIcon icon={faEnvelope} />
                    Email
                  </div>
                  <div className="listItemRight">
                    {/* {customer && customer.username  } */}
                  </div>
                </div>
                <div className="listItem">
                  <div className="listItemLeft">
                    <FontAwesomeIcon icon={faMobile} />
                    Phone
                  </div>
                  <div className="listItemRight">
                    +91{customer && order.shipping_details.shipping_phone}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ordersMainSecComponent ordersMainSecComponent2 squareComponentTable">
            <div className="squareComponentTitle">
              Order #{order && order.shipping_details.order_no}
            </div>
            <div className="squareComponentContent ">
              <Table responsive>
                <thead>
                  <th>PRODUCT</th>
                  <th>QUANTITY</th>
                  <th>UNIT PRICE</th>
                  <th>TOTAL</th>
                </thead>
                <tbody>
                  {order &&
                    order.shipping_details.product.map((item) => {
                      return (
                        <tr>
                          <td
                            style={{ display: "flex", gap: "10px" }}
                            className="tableRow"
                          >
                            <div>
                              {" "}
                              <img
                                src={item.img}
                                width="45px"
                                height="45px"
                                style={{ borderRadius: "7px" }}
                              ></img>
                            </div>
                            <div>
                              <div
                                style={{
                                  fontSize: "14px",
                                  lineHeight: "21px",
                                  fontWeight: "600",
                                  color: "#7e8299",
                                }}
                              >
                                {" "}
                                {item.title}
                              </div>
                              <div
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "500",
                                  color: "#a1a5b7",
                                  lineHeight: "19px",
                                }}
                              >
                                {order &&
                                  order.shipping_details.pending_status &&
                                  `Pending Date: ${formatDateToDDMMYYYY(
                                    order.shipping_details.pending_date
                                  )}`}
                                {order &&
                                  order.shipping_details.accept_status &&
                                  `Accepted Date: ${formatDateToDDMMYYYY(
                                    order.shipping_details.accept_date
                                  )}`}
                                {order &&
                                  order.shipping_details.shipped_status &&
                                  `Shipped Date: ${formatDateToDDMMYYYY(
                                    order.shipping_details.shipped_date
                                  )}`}
                                {order &&
                                  order.shipping_details.deliver_status &&
                                  `Delivery Date: ${formatDateToDDMMYYYY(
                                    order.shipping_details.deliver_date
                                  )}`}
                                {order &&
                                  order.shipping_details.cancel_status &&
                                  `Cancelled Date: ${formatDateToDDMMYYYY(
                                    order.shipping_details.cancel_date
                                  )}`}
                              </div>
                            <div>
                              {order.shipping_details.cancel_status ? (
                                <h6>Cancelled Reason - <i class="fa fa-xin" aria-hidden="true"></i>{order.shipping_details.note}</h6>
                              ) : // You can add another component or element here if needed
                              null}
                            </div>
                            </div>

                          </td>
                          <td className="tableRow tableRow2">{item.qty}</td>
                          <td className="tableRow tableRow2">{item.price}</td>
                          <td className="tableRow tableRow2">{item.total}</td>
                        </tr>
                      );
                    })}
                  <tr>
                    <td></td>
                    <td></td>
                    <td className="tableRow tableRow2">Subtotal</td>
                    <td className="tableRow tableRow2">{subtotal}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td className="tableRow tableRow2">Shipping Rate</td>
                    <td className="tableRow tableRow2">
                      {order && order.shipping_details.shipping_charge
                        ? order.shipping_details.shipping_charge
                        : 0}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td className="tableRow">Grand Total</td>
                    <td className="tableRow">
                      {order && order.shipping_details.grand_total
                        ? order.shipping_details.grand_total
                        : 0}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <div className="ordersMainSecComponent ordersMainSecComponent3">
            <div className="leftComponent squareComponent">
              {" "}
              <div className="squareComponentTitle">Billing Address</div>
              <div className="squareComponentContent squareComponentContent2">
                <div className="squareComponentConLeft">
                  {order && order?.shipping_details?.user_address_line1}
                  <br></br>
                  {order && order?.shipping_details?.user_address_line2}
                  <br></br>
                  {order && order?.shipping_details?.user_city}
                  <br></br>
                  {order && order?.shipping_details?.user_state}
                  <br></br>
                  {order && order?.shipping_details?.user_country}
                  <br></br>
                  {order && order?.shipping_details?.user_zip}
                </div>
                <div className="squareComponentConRight">
                  <FontAwesomeIcon icon={faCreditCard} />
                </div>
              </div>{" "}
            </div>
            <div className="rightComponent squareComponent">
              {" "}
              <div className="squareComponentTitle">Shipping Address</div>
              <div className="squareComponentContent squareComponentContent2">
                <div className="squareComponentConLeft">
                  {order && order?.shipping_details?.shipping_address1}
                  <br></br>
                  {order && order?.shipping_details?.shipping_address2}
                  <br></br>
                  {order && order?.shipping_details?.shipping_city}
                  <br></br>
                  {order && order?.shipping_details?.shipping_state}
                  <br></br>
                  {order && order?.shipping_details?.shipping_country}
                  <br></br>
                  {order && order?.shipping_details?.shipping_zip}
                </div>
                <div className="squareComponentConRight">
                  <FontAwesomeIcon icon={faTruck}></FontAwesomeIcon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="orderDetailsMainBottom">
        <Footer></Footer>
      </div>
    </div>
  );
}

export default OrderDetailsMain;
