import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Button,
} from "reactstrap";
import Swal from "sweetalert2";
import { BASE_URL, cookieFetcher } from "../../helper";

function OrdersDropdown({ status, id, setOrders }) {
  const fetcher = async () => {
    const res = await fetch(
      `${BASE_URL}/customer/order/get_all_order/${cookieFetcher("vendorId")}`
    );
    const response = await res.json();
    console.log(response);
    if (response.status === 200) {
      setOrders(response.data);
    }
  };

  const statusChanger = async (endpoint) => {
    try {
      const res = await fetch(`${BASE_URL}/customer/order/${endpoint}/${id}`, {
        method: "PUT",
        headers: { Authorization: `Bearer ${cookieFetcher("token")}` },
      });
      const response = await res.json();

      if (response.status === 200) {
        await fetcher(); // Fetch updated orders
        window.location.reload(); // Reload the page after successful status change
      } else {
        throw new Error(`Failed to ${endpoint} the order: ${response.message}`);
      }
    } catch (error) {
      console.error("Error changing order status:", error);
      Swal.fire({
        icon: "error",
        title: "Failed!",
        text: error.message,
      });
    }
  };

  const accept = () => {
    statusChanger("accept");
  };

  const ship = () => {
    statusChanger("shipped");
  };

  const deliver = () => {
    statusChanger("deliver");
  };

  const cancel = () => {
    const cancelling = async (notes) => {
      try {
        const res1 = await fetch(`${BASE_URL}/customer/order/cancel/${id}`, {
          method: "PUT",
          headers: { Authorization: `Bearer ${cookieFetcher("token")}` },
        });
        const response1 = await res1.json();

        if (response1.status === 200) {
          const res2 = await fetch(`${BASE_URL}/customer/order/note/${id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${cookieFetcher("token")}`,
            },
            body: JSON.stringify({ note: notes }),
          });

          const response2 = await res2.json();
          if (response2.status === 200) {
            await fetcher(); // Fetch updated orders
            window.location.reload(); // Reload the page after successful cancellation and note addition
          }
        } else {
          throw new Error(`Failed to cancel the order: ${response1.message}`);
        }
      } catch (error) {
        console.error("Error cancelling order:", error);
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: error.message,
        });
      }
    };

    Swal.fire({
      title: "Enter a note for the customer:",
      input: "text",
      inputLabel: "Note",
      showCancelButton: true,
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel",
      inputValidator: (value) => {
        if (!value) {
          return "Please enter a note!";
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const note = result.value;
        cancelling(note);
      }
    });
  };

  return (
    <UncontrolledDropdown>
      <DropdownToggle caret>
        <Button color="dark">{status}</Button>
      </DropdownToggle>
      <DropdownMenu>
        {status === "Accepted" && (
          <>
            <DropdownItem onClick={ship}>Ship</DropdownItem>
            <DropdownItem onClick={deliver}>Deliver</DropdownItem>
            <DropdownItem onClick={cancel}>Cancel</DropdownItem>
          </>
        )}
        {status === "Cancelled" && (
          <DropdownItem onClick={accept}>Accept</DropdownItem>
        )}
        {status === "Delivered" && (
          <>
            <DropdownItem onClick={cancel}>Cancel</DropdownItem>
          </>
        )}
        {status === "Shipped" && (
          <>
            <DropdownItem onClick={deliver}>Deliver</DropdownItem>
            <DropdownItem onClick={cancel}>Cancel</DropdownItem>
          </>
        )}
        {status === "Pending" && (
          <>
            <DropdownItem onClick={accept}>Accept</DropdownItem>
            <DropdownItem onClick={ship}>Ship</DropdownItem>
            <DropdownItem onClick={deliver}>Deliver</DropdownItem>
            <DropdownItem onClick={cancel}>Cancel</DropdownItem>
          </>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default OrdersDropdown;
