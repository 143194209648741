import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import ListLoader from '../FormLoader/ListLoader'
import 'react-loading-skeleton/dist/skeleton.css'

function HomeLoader() {
  return (
    <div className='homeLoader'>
      <div
        className='homeLoaderLine'
        style={{ display: 'flex', gap: '20px', justifyContent: 'center' }}
      >
        <div
          className='homeLoaderBox'
          style={{ display: 'flex', gap: '20px', justifyContent: 'center' }}
        >
          <SkeletonTheme>
            <Skeleton width='150px' height='100px'></Skeleton>
          </SkeletonTheme>
        </div>
        <div className='homeLoaderBox'>
          <SkeletonTheme>
            <Skeleton width='150px' height='100px'></Skeleton>
          </SkeletonTheme>
        </div>
        <div className='homeLoaderBox'>
          <SkeletonTheme>
            <Skeleton width='150px' height='100px'></Skeleton>
          </SkeletonTheme>
        </div>
        <div className='homeLoaderBox'>
          <SkeletonTheme>
            <Skeleton width='150px' height='100px'></Skeleton>
          </SkeletonTheme>
        </div>
      </div>
      <div className='homeLoaderLine'>
        <ListLoader rows={10} cols={7}></ListLoader>
      </div>
    </div>
  )
}

export default HomeLoader
