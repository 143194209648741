import React, { useEffect, useRef, useState } from 'react'
import '../AddProductForm/AddProductForm.css'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import FourImageSelector from './FourImageSelector'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import NavigationBox from '../NavigationBox/NavigationBox'
import { createProduct } from '../AddProductForm/CreateProduct'
import Select from 'react-select'
import {
  BASE_URL,
  cookieFetcher,
  defaultFeatureImage,
  defaultLogo,
} from '../../helper'
import Warning from '../Warning/Warning'
// import FormLoader from '../FormLoader/FormLoader'
import { productEditor } from './ProductEditor'

function EditProductForm({ product,id }) {
  const [featureImageLink, setFeatureImageLink] = useState(
    'https://res.cloudinary.com/nikhil-patil-web/image/upload/v1688966827/LofazInputImage_narqjv.png'
  )
  const [featureImage, setFeatureImage] = useState(
    'https://res.cloudinary.com/nikhil-patil-web/image/upload/v1688966827/LofazInputImage_narqjv.png'
  )
  const [filesSelected, setFilesSelected] = useState(true)
  const [images, setImages] = useState(new Array(4))
  const [productName, setProductName] = useState('')
  const [productStatus, setProductStatus] = useState(true)
  const [productCategory, setProductCategory] = useState('')
  const [salesPrice, setSalesPrice] = useState(0)
  const [mrp, setMrp] = useState(0)
  const [description, setDescription] = useState('')
  const [stock, setStock] = useState(0)
  const [label, setLabel] = useState('new')
  const [unit, setUnit] = useState('607560c37b363921bc960299')
  const [listOfCatalogs, setListOfCatalogs] = useState([])
  const [listOfUnits, setListOfUnits] = useState([])
  const [nameWarning, setNameWarning] = useState(false)
  const [priceWarning, setPriceWarning] = useState(false)
  const [productCatPlaceholder, setProductCatPlaceholder] = useState('')
  const [productUnitPlaceholder, setProductUnitPlaceholder] = useState('')
  const [productStatusPlaceholder, setProductStatusPlaceholder] = useState('')
  const [mrpAndPriceWarning, setMrpAndPriceWarning] = useState(false)

  const [loading, setLoading] = useState(false)
  const [formLoader, setFormLoader] = useState(true)

  const inputFile = useRef(null)
  const inputMultipleFile = useRef(null)
 

  const onButtonClick = () => {
    inputFile.current.click()
    console.log(inputFile ,"input file");
  }

  const onButtonClickMultiple = () => {
    inputMultipleFile.current.click()
  }
  
  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0]
    if (!fileObj) {
      return
    }
    setFeatureImage(event.target.files[0])
    setFeatureImageLink(URL.createObjectURL(event.target.files[0]))
  }

  const handleMultipleFileChange = (e) => {
    // if (e.target && e.target.files.length > 0) {
      // let tempArr = new Array(4)
      // console.log(tempArr,"temparr");
    //   if (e.target.files[0]) {
    //     tempArr[0] = e.target.files[0]
    //   }
    //   if (e.target.files[1]) {
    //     tempArr[1] = e.target.files[1]
    //   }
    //   if (e.target.files[2]) {
    //     tempArr[2] = e.target.files[2]
    //   }
    //   if (e.target.files[3]) {
    //     tempArr[3] = e.target.files[3]
    //   }
      // setImages(tempArr)
      setFilesSelected(true) 
    // }
  }

  useEffect(() => {
    if (product.photo === null) {
      setImages([{}, {}, {}, {}, {}])
    } else {
      setImages(product?.photo)
    }
    setFeatureImage(product.photo && product?.photo[0])
    setFeatureImageLink(
      product.photo && product.photo[0]
        ? product.photo[0].location
        : defaultFeatureImage
    )
    setProductName(product?.title)
    setProductCategory(product.cat_id)
    setLabel(product?.trends)
    setDescription(product.desc)
    setUnit(product.unit?._id)
    setStock(product.stock)
    setProductStatus(product?.status)
    setMrp(product?.mrp)
    setSalesPrice(product?.sales_price)
    setProductUnitPlaceholder(product.unit?.unit)

    const fetcherUnit = async () => {
      setFormLoader(true)
      const res = await fetch(`${BASE_URL}/unit`)
      const response = await res.json()
      if (response.status === 201) {
        let arr = []
        if (response.data.length > 0) {
          response.data.map((item) => {
            arr.push({ value: item._id, label: item.unit })
          })
        }
        setListOfUnits(arr)
      }
    }

    const fetcherCatalog = async () => {
      const res = await fetch(
        `${BASE_URL}/vendor/catalog/${cookieFetcher('vendorId')}`
      )
      const response = await res.json()
      if (response.status === 200) {
        let arr = []
        if (response.data.length > 0) {
          response.data.map((item, index) => {
            arr.push({ value: item._id, label: item.title })
          })
          // console.log(response);
        }
        setListOfCatalogs(arr)
        setFormLoader(false)
      }
    }

    fetcherUnit()
    fetcherCatalog()
  }, [product])

  useEffect(() => {
    listOfCatalogs.map((item, index) => {
      if (item.value === productCategory) {
        setProductCatPlaceholder(item.label)
      }
    })
  }, [productCategory])

  useEffect(() => {
    // console.log('featureImage=======>', featureImage)
    // console.log('images============>', images)
    // console.log('productCategory==========>', productCategory)
    console.log('productName============>', productName)
    // console.log('productStatus==========>', productStatus)
    // console.log('unit=============>', unit)
    // console.log('salesPrice==============>', salesPrice)
    // console.log('mrp==========>', mrp)
    // console.log('description===========>', description)
    // console.log('stock===========>', stock)
    // console.log('label============>', label)
    // console.log('productStatus=============>', productStatus)
  }, [
    featureImage,
    images,
    productCategory,
    productName,
    unit,
    salesPrice,
    mrp,
    productStatus,
    description,
    stock,
    label,
  ])
  const handlesubmit = async () => {
  console.log(product)

    const formData = new FormData();
    const token = cookieFetcher('token');
    console.log(`Bearer ${token}`)
        const response = await fetch(`https://api.lofaz.com/api/vendor/catalog/product/${id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
        body: formData,
      });
      
      const responseText = await response.text();
      if (response.headers.get('content-type')?.includes('application/json')) {
        const data = JSON.parse(responseText);
        console.log('Parsed response data:', data);
      } else {
        console.log('Non-JSON response:', responseText);
      }
      
  };
    


  return (
    <>
      {!formLoader ? (
        <>
          <NavigationBox
            productStatus={productStatus}
            setProductStatus={setProductStatus}
            type={'product'}
            addProductFeatureImage={featureImage}
            addProductName={productName}
            addProductImages={images}
            edit={true}
            addProductCategory={productCategory}
            addProductUnit={unit}
            addProductSalesPrice={salesPrice}
            addProductMrp={mrp}
            addProductDescription={description}
            addProductStock={stock}
            addProductLabel={label}
            id={product._id}
            setNameWarning={setNameWarning}
            setPriceWarning={setPriceWarning}
            setLoading={setLoading}
            setMrpAndPriceWarning={setMrpAndPriceWarning}
            nameWarning={nameWarning}
            priceWarning={priceWarning}
            mrpAndPriceWarning={mrpAndPriceWarning}
          ></NavigationBox>
          <div className='addProductForm'>
            {/*Here begins the first part of the add product form where user inputs the media files related to the product */}
            <div
              className='addProductFormComponent productMediaForm'
              id='productMedia'
            >
              <div className='productMediaLeft'>
                <div
                  className='productMediaTitle'
                  style={{
                    textAlign: 'left',
                    color: '#181c32',
                    fontWeight: '600',
                    fontSize: '20px',
                    lineHeight: '23px',
                  }}
                >
                  Media
                </div>

                <div
                  className='productMediaFeatureImage'
                  onClick={() => onButtonClick()}
                >
                  <input
                    type='file'
                    style={{ display: 'none' }}
                    ref={inputFile}
                    onChange={(e) => {
                      handleFileChange(e)
                    }}
                  ></input>
                  <img
                    src={featureImageLink}
                    style={{
                      border: 'dashed #009ef7 1px',
                      borderRadius: '5px',
                      boxShadow: '0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075)',
                      width: '160px',
                      height: '160px',
                    }}
                  ></img>
                </div>

                <div className='featureImageBottomText'>
                  <div
                    style={{
                      fontWeight: '700',
                      fontSize: '14px',
                      color: '#181c32',
                      lineHeight: '17px',
                    }}
                  >
                    Featured Image
                  </div>
                  <div
                    style={{
                      fontSize: '12px',
                      color: '#a1a5b7',
                      fontWeight: '400',
                    }}
                  >
                    *1000 × 1000 size
                  </div>
                  <div
                    style={{
                      fontSize: '12px',
                      color: '#a1a5b7',
                      fontWeight: '400',
                    }}
                  >
                    *less than 1 mb
                  </div>
                  <div></div>
                </div>
              </div>
              {!filesSelected ? (
                <div className='productMediaRight'>
                  <input
                    type='file'
                    multiple
                    style={{ display: 'none' }}
                    ref={inputMultipleFile}
                    onChange={(e) => {
                      handleMultipleFileChange(e)
                    }}
                  ></input>
                  <div
                    className='dropFilesSection'
                    onClick={() => onButtonClickMultiple()}
                  >
                    <div className='dropFilesIcon'>
                      <img src='https://res.cloudinary.com/nikhil-patil-web/image/upload/v1688971931/LofazUploadImage_bykfaq.png'></img>
                    </div>
                    <div className='dropFilesTexts'>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '17px',
                          fontWeight: '600',
                          color: '#181c32',
                        }}
                      >
                        Drop files here or click to upload.
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '13px',
                          fontWeight: '500',
                          color: '#b5b5c3',
                        }}
                      >
                        Upload up to 4 files
                      </div>
                    </div>
                  </div>
                  <div className='galleryImagesText'>
                    <div
                      style={{
                        textAlign: 'left',
                        fontWeight: '700',
                        fontSize: '14px',
                        color: '#181c32',
                        lineHeight: '17px',
                      }}
                    >
                      Gallery Images
                    </div>
                    <div
                      style={{
                        textAlign: 'left',

                        fontSize: '12px',
                        color: '#a1a5b7',
                        fontWeight: '400',
                      }}
                    >
                      *1000x1000 size with size less than 1 mb
                    </div>
                  </div>
                </div>
              ) : (
                <FourImageSelector
                  images={images}
                  setImages={setImages}
                ></FourImageSelector>
              )}
            </div>

            {/* Here begins the second part of the form in which the user fills the product info */}

            <div
              className='addProductFormComponent productInfoComponent'
              id='productInfo'
            >
              <div className='productInfoTitle'>Product Information</div>
              <div className='productNameAndCategoryInputs'>
                <div className='inputName'>
                  <div
                    className='inputHeading'
                    style={{
                      textAlign: 'left',
                      color: '#3f4254',
                      fontWeight: '500',
                    }}
                  >
                    Product Name
                  </div>
                  <input
                    placeholder='Product Name'
                    style={{
                      width: '100%',
                      border: ' solid 1px #cccccc',
                      padding: '7px',
                      paddingLeft: '10px',
                      borderRadius: '5px',
                      fontFamily: 'Inter',
                      fontSize: '15px',
                      fontWeight: '500',
                    }}
                    value={productName}
                    onChange={(e) => {
                      setNameWarning(false)
                      setProductName(e.target.value)
                    }}
                  ></input>
                  {nameWarning && <Warning type={'name'}></Warning>}
                </div>
                <div className='inputCategory'>
                  <div
                    className='inputHeading'
                    style={{
                      textAlign: 'left',
                      color: '#3f4254',
                      fontWeight: '500',
                    }}
                  >
                    Product Category
                  </div>
                  <Select
                    options={listOfCatalogs}
                    placeholder={
                      productCatPlaceholder && productCatPlaceholder.length > 0
                        ? productCatPlaceholder
                        : 'Select an option'
                    }
                    onChange={(e) => {
                      setProductCategory(e.value)
                    }}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  ></Select>
                </div>
              </div>
              <div
                className='productDescriptionInput'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                <div
                  className='productDescriptionTitle'
                  style={{
                    textAlign: 'left',
                    color: '#181c32',
                    fontWeight: '500',
                  }}
                >
                  Description
                </div>
                <div className='productDescriptionTE'>
                  <ReactQuill
                    theme='snow'
                    style={{
                      textAlign: 'left',
                      color: '#a1a5b7',
                    }}
                    onChange={(e) => {
                      setDescription(e)
                    }}
                    value={description}
                    placeholder='Type your text here........'
                  ></ReactQuill>
                </div>
              </div>
              <div className='productMSC'>
                <div className='productMSCcomponent productMRP'>
                  <div className='inputHeading'>MRP</div>
                  <input
                    placeholder='MRP'
                    value={mrp}
                    onChange={(e) => {
                      setPriceWarning(false)
                      setMrp(e.target.value)
                    }}
                    type='number'
                  ></input>
                  {priceWarning && <Warning type={'mrp'}></Warning>}
                  {mrpAndPriceWarning && (
                    <Warning type={'mrpAndSales'}></Warning>
                  )}
                </div>
                <div className=' productMSCcomponent productSalePrice'>
                  <div className='inputHeading'>Sale Price</div>
                  <input
                    type='number'
                    value={salesPrice}
                    onChange={(e) => {
                      setSalesPrice(e.target.value)
                    }}
                    placeholder='Sale Price'
                  ></input>
                </div>
                <div className=' productMSCcomponent productCostPrice'>
                  <div className='inputHeading'>Cost Per Item</div>
                  <input disabled placeholder='Cost Per Item'></input>
                  <div className='costText'>
                    This will not be visible to user
                  </div>
                </div>
              </div>
            </div>

            {/* Here begins the second part of the form in which the user fills the inventory info of the product */}

            <div
              className='addProductFormComponent productFormInventoryComponent'
              id='inventory'
            >
              <div className='inventoryTitle'>Inventory</div>
              <div className='inventoryInputs'>
                <div className='inputSKU inputSKUAdd'>
                  <div className='inputInventoryTitle'>
                    SKU <span>*</span>
                  </div>
                  <input disabled placeholder='SKU Number'></input>
                </div>
                <div className='inputQuantity inputQuantityAdd'>
                  <div className='inputInventoryTitle'>Quantity</div>
                  <input
                    value={stock}
                    onChange={(e) => {
                      setStock(e.target.value)
                    }}
                    placeholder='Quantity'
                  ></input>
                </div>
                <div className='inputQuantity inputQuantityAdd'>
                  <div className='inputInventoryTitle'>Unit</div>
                  <Select
                    options={listOfUnits}
                    placeholder={
                      productUnitPlaceholder &&
                      productUnitPlaceholder.length > 0
                        ? productUnitPlaceholder
                        : 'PIECE'
                    }
                    // styles={}
                    onChange={(e) => {
                      setUnit(e.value)
                    }}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  ></Select>
                </div>
              </div>
            </div>

            <div
              className='addProductFormComponent prodcutFormSEOComponent'
              id='seo'
            >
              <div className='seoFormComp seoTitle'>SEO</div>
              <div className=' seoFormComp metaTagTile'>
                <div className='seoCompSubtitles'>Meta Tag Title</div>
                <input placeholder='Meta tag name'></input>
                <div className='seoCompInfo'>
                  Set a meta tag title. Recommended to be simple and precise
                  keywords.
                </div>
              </div>
              <div className=' seoFormComp metaTagDescription'>
                <div className='seoCompSubtitles'>Meta Tag Description</div>
                <div className=''>
                  <ReactQuill
                    theme='snow'
                    style={{
                      textAlign: 'left',
                      color: '#a1a5b7',
                    }}
                    placeholder='Type your text here........'
                  ></ReactQuill>
                </div>
                <div className='seoCompInfo'>
                  Set a meta tag description to the product for increased SEO
                  ranking.
                </div>
              </div>
              <div className=' seoFormComp tags'>
                <div className='seoCompSubtitles tagsSubtitle'>
                  Tags <span style={{ color: 'red' }}>*</span>
                </div>
                <input
                  placeholder=''
                  style={{
                    background: '#f9f9f9',
                    border: 'none',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    paddingLeft: '10px',
                    paddingTop: '7px',
                    paddingBottom: '7px',
                    color: '#5e6278',
                    fontWeight: 500,
                  }}
                  defaultValue='Important, Urgent'
                ></input>
              </div>
              <div className=' seoFormComp metaTagKeywords'>
                <div className='seoCompSubtitles'>Meta Tag Keywords</div>
                <input placeholder=''></input>
                <div className='seoCompInfo'>
                  Set a list of keywords that the product is related to.
                  Separate the keywords by adding a comma{' '}
                  <span
                    style={{
                      background: '#f1f3f8',
                      paddingTop: '2px',
                      paddingLeft: '8px',
                      paddingRight: '8px',
                      borderRadius: '3px',
                      paddingBottom: '5px',
                      color: 'purple',
                    }}
                  >
                    ,
                  </span>{' '}
                  between each keyword.
                </div>
              </div>
            </div>
            <div className='addProductFormComponent saveButtonComponent'>
              <div style={{ display: 'flex', gap: '20px' }}>
                <div className='cancelButton'>Cancel</div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                  }}
                >
                  {!loading ? (
                    <div
                      className='saveButton'
                      onClick={(e) => {
                        e.preventDefault(); // Prevent default form submission behavior if this is inside a form
                        handlesubmit();
                      
                        // productEditor(
                        //   featureImage,
                        //   images,
                        //   productCategory,
                        //   productName,
                        //   productStatus,
                        //   unit,
                        //   salesPrice,
                        //   mrp,
                        //   description,
                        //   stock,
                        //   label,
                        //   product._id,
                        //   setNameWarning,
                        //   setPriceWarning,
                        //   setLoading
                        // )
                      }}
                    >
                      Save Changes
                    </div>
                  ) : (
                    <div className='saveLoading' style={{ background: '' }}>
                      <FontAwesomeIcon icon={faSpinner} spin>
                        {' '}
                      </FontAwesomeIcon>
                    </div>
                  )}
                  {(nameWarning || priceWarning) && (
                    <div
                      style={{
                        color: 'red',
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '10px',
                        padding: '',
                      }}
                    >
                      <span>
                        {' '}
                        *A few mandatory fields are empty.
                        <br /> Please check above.
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        // <FormLoader></FormLoader>
        <></>
      )}
    </>
  )
}

export default EditProductForm
