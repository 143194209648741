import React, { useState,useEffect } from 'react'
import Sidebar from '../../components/Sidebar/Sidebar'
import OrdersHeader from '../Orders/OrdersHeader'
import Footer from '../../components/Footer/Footer'
import './QrCode.css'
import QRCode from 'react-qr-code'
import { BASE_URL,cookieCreator, cookieFetcher } from '../../helper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faShare } from '@fortawesome/free-solid-svg-icons'

import { Helmet } from 'react-helmet'

function QrCode() {const [sidebar, setSidebar] = useState(true);
  const [photo, setPhoto] = useState(
    'https://ipfs.filebase.io/ipfs/QmZ6mAp25xPqcmpFrhS4zg5UFvwDDnbAGPrS1NZUrQ11UH/QmcES1BYBhrtw2YU1CWPkbY1JDZbVtLcLvzfjQmo4yF2Ja'
  );
  const [storeName, setStoreName] = useState('');
  const [userlink, setUserlink] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [username, setUsername] = useState('');
  useEffect(() => {
    const fetcher = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/vendor/visitor/${cookieFetcher('username')}`
        );
        const res = await response.json();
        console.log(res.data);
        setUsername(res.data.username);
        setPhoneNumber(res.data.phone);
        setUserlink(res.data.username);
        setStoreName(res.data.business_name);
        
        if (res.data.photo && res.data.photo.length > 0) {
          setPhoto(res.data.photo);
        } else {
          setPhoto('https://ipfs.filebase.io/ipfs/QmZ6mAp25xPqcmpFrhS4zg5UFvwDDnbAGPrS1NZUrQ11UH/QmcES1BYBhrtw2YU1CWPkbY1JDZbVtLcLvzfjQmo4yF2Ja');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error state if needed
      }
    };
    fetcher();
  }, []);
  
  // Render your component with the photo state now containing the appropriate value
  
  return (
    <div className='qrCodePage'>
      <Helmet
        title='QR Code'
        link={{ rel: 'icon', type: 'image/png', href: cookieFetcher('photo') }}
      ></Helmet>
      <Sidebar sidebar={sidebar}></Sidebar>
      <div className='qrCodeMain'>
        <div className='qrCodeMainTop'>
          <OrdersHeader
            sidebar={sidebar}
            setSidebar={setSidebar}
          ></OrdersHeader>
          <div className='qrCodeMainSec'>
            <div className='qrCard'>
              <div className='qrCodeHolder'>
                <div className='qrCodeTitle'>
                  <div className='qrCodeImage'>
                    <img src={photo} width='35px' height='35px'></img>
                  </div>
                  <div className='qrCodeTitleText'>Lofaz</div>
                </div>
                <div className='qrDetails'>
                  <div className='qrCodeStoreName'>{storeName}</div>
                  <div className='qrCodeCustomerLink'>{userlink}</div>
                  <div className='qrCodeNumber'>{phoneNumber}</div>
                </div>

                <QRCode
                  height='150px'
                  width='150px'
                  style={{ width: '130px', height: '130px' }}
                  value={`https://lofaz.in/${cookieFetcher('username')}`}
                />
                <div className='qrCallForAction'>
                  Scan the above QR code to shop online.
                </div>
                <div className='qrCodeButtons'>
                  <div className='qrButton'>
                    Download{' '}
                    <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                  </div>
                  <div className='qrButton'>
                    Share<FontAwesomeIcon icon={faShare}></FontAwesomeIcon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='qrCodeMainBottom'>
          <Footer></Footer>
        </div>
      </div>
    </div>
  )
}

export default QrCode
