import React, { useEffect } from 'react'
import Logo from '../../metronic.png'
import { BASE_URL, cookieCreator, cookieFetcher } from '../../helper'
import { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesLeft, faAnglesRight } from '@fortawesome/free-solid-svg-icons'

// import Store from '@flaticon/flaticon-uicons'

function SidebarHeader({ name, icon }) {
  const [business, setBusiness] = useState('')

  useEffect(() => {
    const fetcher = async () => {
      const response = await fetch(
        `${BASE_URL}/vendor/check_number/${cookieFetcher('number')}`
      )
      const res = await response.json()
      if (res.status === 201) {
        setBusiness(res.data.business_name)
        cookieCreator('username', res.data.username)
      } else {
        console.log(res.message)
      }
    }
    fetcher()
  }, [])

  return (
    <>
      <div className='sidebarHeader'>
        <div className='imageHolder'>
          <img
            src='https://ik.imagekit.io/kotharitech/Lofaz/Lofaz-admin/lofaz_logo.png?updatedAt=1716464379919'
            width='25px'
          // height='25px'
          ></img>
          <div className='imageHolderAndStoreName ' style={{fontSize:"13px"}}>{business}</div>
          <span class="material-symbols-outlined" style={{
            color: 'white',
            marginBottom: '-4px',
            // marginRight:"",
            fontSize: '20px',
            cursor: 'pointer',
          }} onClick={() => {
            window.open(`https://lofaz.in/test23`, '_blank')
            // window.open(`https://${userLink}`, '_blank')
          }}>
           open_in_new
          </span>
          {/* <div className='sliderbar_toggle_icon'>
        arrow
        </div> */}
        </div>




      </div>


    </>
  )
}


export default SidebarHeader
