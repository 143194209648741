import Swal from 'sweetalert2';
import { BASE_URL, cookieFetcher } from '../../helper';

export const productEditor = async (
  featureImage,
  images,
  productCategory,
  productName,
  productStatus,
  unit,
  salesPrice,
  mrp,
  description,
  stock,
  label,
  id,
  setNameWarning,
  setPriceWarning,
  setLoading,
  setMrpAndPriceWarning
) => {
  // const formData = new FormData();
  // console.log(mrp);
  // console.log(salesPrice);
  // console.log(mrp > salesPrice);
  // const trimmedName = productName ? productName.trim() : '';
  
  
  // if (trimmedName.length === 0 && mrp === 0) {
  //   setLoading(false);
  //   setPriceWarning(true);
  //   setNameWarning(true);
  //   return;
  // } else if (mrp === 0 && trimmedName.length > 0) {
  //   setLoading(false);
  //   setPriceWarning(true);
  //   return;
  // } else if (mrp !== 0 && trimmedName.length === 0) {
  //   setLoading(false);
  //   setNameWarning( true);
  //   return;
  // } else if (mrp < salesPrice) {
  //   setLoading(false);
  //   setMrpAndPriceWarning(true);
  //   return;
  // } else {
  //   const arrayToBeAttached = [];

  //   if (featureImage && featureImage.location) {
  //     arrayToBeAttached.push(featureImage);
  //   } else if (featureImage && featureImage.name) {
  //     arrayToBeAttached.push({});
  //     formData.append('photo', featureImage);
  //   } else if (!featureImage) {
  //     arrayToBeAttached.push(0);
  //   }
  //   // console.log(images,"image")
  //   if (images && images.length > 0) {
  //     images.map((item, index) => {
  //       if (index !== 0) {
  //         if (item && item.location) {
  //           arrayToBeAttached.push(item);
  //         } else if (item && item.name) {
  //           arrayToBeAttached.push({});
  //           formData.append('photo', item);
  //         } else {
  //           arrayToBeAttached.push(0);
  //         }
  //       }
  //     });
  //   }

    // formData.append('photo', JSON.stringify(arrayToBeAttached));
    // formData.append('cat_id', productCategory);
    // formData.append('title', productName);
    // formData.append('status', productStatus);
    // formData.append('unit', unit);
    // formData.append('sales_price', salesPrice);
    // formData.append('mrp', mrp);
    // formData.append('desc', description);
    // formData.append('stock', stock === null || stock === undefined ? 0 : stock);
    // formData.append('trends', label);
    // console.log(formData);
    // setLoading(true);
    // console.log(`hello this is my product  ${formData.append('title', productName)};,  ${productName}`);
    // try {
    //   const token = cookieFetcher('token');
    //   const response = await fetch(`https://api.lofaz.com/api/vendor/catalog/product/668172f707d79b31d8051ba8`, {
    //     method: 'PUT',
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //     body: formData,
    //   });
    
    //   if (!response.ok) {
    //     throw new Error(`HTTP error! Status: ${response.status}`);
    //   }
    
    //   const data = await response.json();
    //   setLoading(false);
    //   Swal.fire({
    //     icon: 'success',
    //     title: 'Product Edited',
    //     text: `Successfully Edited!`,
    //     confirmButtonText: 'Go to Products',
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       window.location.href = '/products';
    //     }
    //   });
    
    // } catch (error) {
    //   console.error('Fetch Error:', error);
    //   setLoading(false);
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Failed to edit product',
    //     text: `${error.message}`,
    //   });
    // }
    //   }
};
