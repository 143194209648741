import React from 'react'
import { TiFilter } from 'react-icons/ti'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap'

function CatlaogFilterButton({
  setNameSort,
  setCountSort,
  setStatusSort,
  setDateSort,
}) {
  return (
    <UncontrolledDropdown>
      <DropdownToggle
        style={{
          display: 'flex',
          gap: '10px',
          paddingTop: '10px',
          paddingBottom: '10px',
          fontWeight: 600,
          fontSize: '14px',
        }}
      >
        {' '}
        <TiFilter style={{ marginTop: '3px' }}></TiFilter>
        <div>Filter</div>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          onClick={() => {
            setCountSort(false)
            setStatusSort(false)
            setDateSort(false)
            setNameSort(true)
          }}
        >
          Sort By Name
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            setStatusSort(false)
            setNameSort(false)
            setDateSort(false)
            setCountSort(true)
          }}
        >
          Sort By Count
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            setNameSort(false)
            setCountSort(false)
            setDateSort(false)
            setStatusSort(true)
          }}
        >
          Sort By Status
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            setNameSort(false)
            setCountSort(false)
            setStatusSort(false)
            setDateSort(true)
          }}
        >
          Sort By Date
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default CatlaogFilterButton
