import { faEdit } from '@fortawesome/free-regular-svg-icons'
import {
  faPenToSquare,
  faShare,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap'
import Swal from 'sweetalert2'
import { BASE_URL, cookieFetcher } from '../../helper'
import { useNavigate } from 'react-router-dom'

function CatalogsDropDown({ catalog, fetchStatus, setFetchStatus, id }) {
  const navigate = useNavigate()

  const deleting = async (catalog) => {
    const res = await fetch(`${BASE_URL}/vendor/catalog/${catalog._id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${cookieFetcher('token')}`,
      },
    })
    const response = await res.json()
    if (response.status === 200) {
      setFetchStatus(!fetchStatus)
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${response.message}`,
      })
    }
  }

  const catalogDeleter = async (catalog) => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: `Are you sure you want to delete ${catalog.title}?`,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleting(catalog)
      }
    })
  }

  return (
    <UncontrolledDropdown className='catalogActionsDropdown'>
      <DropdownToggle
        style={{ background: '#f9f9f9', color: '#7e8299', border: 'none' }}
        caret
      >
        Actions
      </DropdownToggle>
      <DropdownMenu className='custom-dropdown-menu'>
        <DropdownItem
          style={{ display: 'flex', gap: '10px' }}
          onClick={() => {
            navigate(`/catalog/edit/${id}`)
          }}
        >
          <FontAwesomeIcon
            style={{ marginLeft: '15px', marginTop: '3px' }}
            icon={faPenToSquare}
          ></FontAwesomeIcon>
          Edit
        </DropdownItem>
        <DropdownItem style={{ display: 'flex', gap: '10px' }}>
          {' '}
          <FontAwesomeIcon
            style={{ marginLeft: '15px', marginTop: '3px' }}
            icon={faShare}
          ></FontAwesomeIcon>
          Share
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            catalogDeleter(catalog)
          }}
          style={{ display: 'flex', gap: '10px' }}
        >
          {' '}
          <FontAwesomeIcon
            style={{ marginLeft: '15px', marginTop: '3px' }}
            icon={faTrash}
          ></FontAwesomeIcon>
          Delete
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>

    // <div style={{ display: 'flex', gap: '6px' }}>
    //   <FontAwesomeIcon
    //     style={{ cursor: 'pointer', color: '#009ef7' }}
    //     icon={faPenToSquare}
    //   ></FontAwesomeIcon>
    //   <FontAwesomeIcon
    //     style={{ cursor: 'pointer', color: '#215C54' }}
    //     icon={faShare}
    //   ></FontAwesomeIcon>
    //   <FontAwesomeIcon
    //     style={{ cursor: 'pointer', color: '#B22222' }}
    //     icon={faTrash}
    //     onClick={() => {
    //       catalogDeleter(catalog)
    //     }}
    //   ></FontAwesomeIcon>
    // </div>
  )
}

export default CatalogsDropDown
