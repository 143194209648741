import React from 'react'
import '../Catalog/Catalog.css'
import Sidebar from '../../components/Sidebar/Sidebar'
import OrdersHeader from '../Orders/OrdersHeader'
import Footer from '../../components/Footer/Footer'
import { BsSearch } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { Table } from 'reactstrap'
import { useState } from 'react'
import { useEffect } from 'react'
import {
  BASE_URL,
  cookieFetcher,
  defaultLogo,
  loginChecker,
} from '../../helper'
import {
  SortedCount,
  SortedDate,
  SortedName,
  SortedStatus,
  // sortByCount,
  sortByStatus,
  // sortObjectsByName,
} from './CatalogFilter'
import CatlaogFilterButton from '../../components/Dropdowns/CatlaogFilterButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { Helmet } from 'react-helmet'
import ListLoader from '../../components/FormLoader/ListLoader'
// import ListLoader from '../../components/FormLoader/ListLoader'
import ReactPaginate from 'react-paginate';
function Catalog() {
  const navigate = useNavigate()
  const [catloglimit, setCataloglimit] = useState(10)
  const [allCatalogs, setAllCatalogs] = useState([])
  const [catalogs, setCatalogs] = useState([])
  const [fetchStatus, setFetchStatus] = useState(false)
  const [nameSort, setNameSort] = useState(false)
  const [statusSort, setStatusSort] = useState(false)
  const [countSort, setCountSort] = useState(false)
  const [dateSort, setDateSort] = useState(true)
  const [searchField, setSearchField] = useState('')
  const [listLoader, setListLoader] = useState(true)
  const [sidebar, setSidebar] = useState(true)
   const [currentPage, setCurrentPage] = useState(0); // Current page number, starting from 0

  useEffect(() => {
    const fetcher = async () => {
      setListLoader(true)
      const res = await fetch(
        `${BASE_URL}/vendor/catalog/${cookieFetcher('vendorId')}`
      )
      const response = await res.json()
      console.log(response)
      if (response.status === 200) {
        if (response.data.length > 0) {
          setCatalogs(response.data)
          setAllCatalogs(response.data)
          setListLoader(false)
        }
      }
    }
    loginChecker()
    fetcher()
  }, [fetchStatus])

  const changingSearchInput = (e) => {
    const trimmedField = e.target.value.trim()
    if (trimmedField.length === 0) {
      setSearchField(e.target.value)
      setCatalogs(allCatalogs)
      return
    }
    setSearchField(e.target.value)
    const filteredObjects = catalogs.filter((obj) =>
      obj.title.toLowerCase().includes(e.target.value.toLowerCase())
    )
    setCatalogs(filteredObjects)
    return
  }
  
  const pageCount = Math.ceil(catalogs.length / catloglimit);
  const paginatedCatalog = catalogs.slice(currentPage * catloglimit, (currentPage + 1) * catloglimit);
  console.log(paginatedCatalog,"catalog",pageCount);
   // Function to handle page change
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  return (  
    <div className='catalogPage'>
      <Helmet
        title='Catalogs'
        link={{ rel: 'icon', type: 'image/png', href: cookieFetcher('photo') }}
      ></Helmet>
      
      <Sidebar sidebar={sidebar} product={true} page={'catalogs'}></Sidebar>
      <div className='catalogMain'>
      <OrdersHeader
            sidebar={sidebar}
            setSidebar={setSidebar}
          ></OrdersHeader>
        <div className='catalogMainUp'>
          
          <div className='catalogMainSec'>
            <div className='catalogList'>
              <div className='catalogListButtons'>
                <div className='oSearch'>
                  <div className='searchIcon'>
                    <BsSearch size={25}></BsSearch>
                  </div>
                  
                  <div className='searchText'>
                    <input
                      type='text'
                      placeholder='Search Category'
                      onChange={(e) => {
                        changingSearchInput(e)
                      }}
                      value={searchField}
                    ></input>
                  </div>
                </div>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <CatlaogFilterButton
                    setNameSort={setNameSort}
                    setCountSort={setCountSort}
                    setStatusSort={setStatusSort}
                    setDateSort={setDateSort}
                    
                  ></CatlaogFilterButton>
                  <div
                    className='addCategoryButton'
                    style={{ cursor: 'pointer', fontWeight: '600' }}
                    onClick={() => {
                      navigate('/addCatalog')
                    }}
                  >
                    Add Category
                  </div>
                </div>
              </div>
              {!listLoader ? (
                <Table style={{ marginTop: '25px' }} responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th
                        onClick={() => {
                          setCountSort(false)
                          setStatusSort(false)
                          setDateSort(false)
                          setNameSort(true)
                        }}
                        style={{
                          textAlign: 'left',
                          paddingLeft: '30px',
                          cursor: 'pointer',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            gap: '5px',
                          }}
                        >
                          NAME
                          <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>
                        </div>
                      </th>
                      <th
                        style={{
                          cursor: 'pointer',

                          justifyContent: 'center',
                        }}
                        onClick={() => {
                          setStatusSort(false)
                          setNameSort(false)
                          setDateSort(false)
                          setCountSort(true)
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            gap: '5px',
                            justifyContent: 'center',
                          }}
                        >
                          COUNT
                          <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>
                        </div>
                      </th>
                      <th
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setNameSort(false)
                          setCountSort(false)
                          setDateSort(false)
                          setStatusSort(true)
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            gap: '5px',
                            justifyContent: 'center',
                          }}
                        >
                          STATUS
                          <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>
                        </div>
                      </th>
                      <th></th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dateSort && (
                      <SortedDate
                        catalogs={catalogs}
                        fetchStatus={fetchStatus}
                        setFetchStatus={setFetchStatus}   
                        paginatedCatalog={paginatedCatalog}
                        catloglimit={catloglimit}
                        currentPage={currentPage}
                      ></SortedDate>
                    )}
                    {statusSort && (
                      <SortedStatus
                        catalogs={catalogs}
                        fetchStatus={fetchStatus}
                        setFetchStatus={setFetchStatus}  
                         paginatedCatalog={paginatedCatalog}
                         catloglimit={catloglimit}
                         currentPage={currentPage}
                      ></SortedStatus>
                    )}
                    {nameSort && (
                      <SortedName
                        catalogs={catalogs}
                        fetchStatus={fetchStatus}
                        setFetchStatus={setFetchStatus}  
                         paginatedCatalog={paginatedCatalog}
                         catloglimit={catloglimit}
                         currentPage={currentPage}
                      ></SortedName>
                    )}
                    {countSort && (
                      <SortedCount
                        catalogs={catalogs}
                        setFetchStatus={setFetchStatus}  
                         paginatedCatalog={paginatedCatalog}
                        fetchStatus={fetchStatus}
                        catloglimit={catloglimit}
                        currentPage={currentPage}
                      ></SortedCount>
                    )}
                  </tbody>
                </Table>
                
              ) : (
                <ListLoader rows={10} cols={5}></ListLoader>
              )}
              </div>
              {pageCount==1 ?(
                  <div></div>
                ):(

                  <ReactPaginate
                  variant="outlined" color="primary"
                  previousLabel={'< Previous'}
                  nextLabel={'Next >'}
                  breakLabel={'..........'}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageChange}
                  containerClassName={'pagination flex justify-center mt-4'}
                  pageClassName={'mx-1'}
                  pageLinkClassName={'pagination-link text-blue-500 hover:bg-gray-200'}
                  activeClassName={'active-page'}
                  previousLinkClassName={'pagination-link bg-black  text-white-500 hover:bg-gray-200'}
                  nextLinkClassName={'pagination-link bg-black text-white-500 hover:bg-gray-200'}
                  disabledClassName={'text-gray-400 cursor-not-allowed'}
                  />
                 )}


          </div>
        </div>
        <div className='catalogMainDown'>
          <Footer></Footer>
        </div>
      </div>
    </div>
  )
}

export default Catalog
