import React, { useEffect, useState } from 'react'
import { BASE_URL, cookieFetcher, logout } from '../../helper'
import Swal from 'sweetalert2'
import OrdersMain from '../Orders/OrdersMain'
import Homeorder from './Homeorder'
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fa4, faCheck } from '@fortawesome/free-solid-svg-icons'
import TimelineComponent from './TimelineComponent'
import HomeLoader from '../../components/HomeLoader/HomeLoader'

function HomePageForm() {
  const earningFetcher = async () => {
    setHomeLoader(true)
    const response = await fetch(
      `${BASE_URL}/vendor/count/earning/${cookieFetcher('vendorId')}`
    )
    const res = await response.json()
    if (res.status === 200) {
      setEarning(res.total_earning)
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Something went wrong! in Total Earnings',
        text: `${res.status}, ${res.message}`,
      })
    }
  }
  const storeViewFetcher = async () => {
    const username = cookieFetcher('username');
    if (!username) {
      console.error('Username not found in cookies.');
      return;
    }
  
    const apiUrl = `${BASE_URL}/vendor/count/store/${username}`;
    console.log('API URL:', apiUrl); // Log the API URL for debugging
  
    try {
      const response = await fetch(apiUrl);
      const res = await response.json();
  
      console.log('API Response:', res); // Log the API response for debugging
  
      if (res.views) {
        setStoreView(res.views);
        // setCustomer(res.data);
      } else {
        throw new Error(`${res.status}: ${res.message}`);
      }
    } catch (error) {
      console.error('Failed to fetch store view:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed!',
        text: error.message,
      });
    }
  };
  
  
  // const productViewFetcher = async () => {
  //   const response = await fetch(
  //     `${BASE_URL}/vendor/count/product/${cookieFetcher('vendorId')}`
  //   )
  //   const res = await response.json()
  //   if (res.views) {
  //     setProductView(res.views)
  //   } else {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Something went wrong in Product Views!',
  //       text: `${res.message}`,
  //     })
  //   }
  // }

  const orderFetcher = async () => {
    const res = await fetch(
      `${BASE_URL}/customer/order/get_all_order/${cookieFetcher('vendorId')}`
    )
    const response = await res.json()
    console.log(response)
    if (response.data) {
      setOrdersCount(response.data.length)
    } else {
      // Swal.fire({
      //   icon: 'error',
      //   title: 'Something went wrong in Orders Count!',
      //   text: `${res.message}`,
      // })
      setOrdersCount(0)
    }
  }

  const productFetcher = async () => {
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDBmNDdlNDU5NDliZmFkYTVjZDNkNTEiLCJpYXQiOjE3MTk2NDQwOTIsImV4cCI6MTc1MTE4MDA5Mn0.U3U5r5pTbS_B-bSmWE9BGSAG2SLzcAeSL1Efqsv4pxg'; // Replace with your actual token
  
    const res = await fetch(
      `${BASE_URL}/product/shop/v1`,
      {
        method: 'GET',
        headers: {  
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );

    const response = await res.json()
    if (res.status === 200) {
      setShowDashboardProduct(true)
    } else {
      setShowDashboardProduct(false)
    }
  }

  const catalogFetcher = async () => {
    console.log(`${BASE_URL}`);

    const response = await fetch(
      `${BASE_URL}/vendor/catalog/${cookieFetcher('vendorId')}`
    )
    const res = await response.json()
    console.log(res)
    if (res.status === 200) {
      setShowDashboardCatalog(true)
      setHomeLoader(false)
    } else {
      console.log('Returned false........')
      setShowDashboardCatalog(false)
    }
  }

  const [earning, setEarning] = useState(0)
  const [storeView, setStoreView] = useState(0)
  const [productView, setProductView] = useState(0)
  const [ordersCount, setOrdersCount] = useState(0)
  const [showDashboardProduct, setShowDashboardProduct] = useState(false)
  const [showDashboardCatalog, setShowDashboardCatalog] = useState(false)
  const [homeLoader, setHomeLoader] = useState(true)

  useEffect(() => {
    earningFetcher()
    storeViewFetcher()
    // productViewFetcher()
    orderFetcher()
    productFetcher()
    catalogFetcher()
  }, [])

  return (
    <div>
      {!homeLoader ? (
        <>
          {showDashboardProduct && showDashboardCatalog ? (
            <div className='homePageMain'>
              <div className='homePageComponent'>
                <div className='homePageSquare'>
                  <div className='homePageSquareTitle'>Total Earning</div>
                  <div className='homePageSquareContent'>₹{earning}</div>
                </div>
                <div className='homePageSquare'>
                  <div className='homePageSquareTitle'>Store Views</div>
                  <div className='homePageSquareContent'>{storeView}</div>
                </div>
                <div className='homePageSquare'>
                  <div className='homePageSquareTitle'>Product Views</div>
                  <div className='homePageSquareContent'>{productView}</div>
                </div>
                <div className='homePageSquare'>
                  <div className='homePageSquareTitle'>Total Orders</div>
                  <div className='homePageSquareContent'>{ordersCount}</div>
                </div>
              </div>

              {/* <OrdersMain></OrdersMain> */}
              {/* <Homeorder></Homeorder> */}
            </div>
          ) : (
            <div className='homePageMain'>
              <TimelineComponent></TimelineComponent>
              <VerticalTimeline>
            <VerticalTimelineElement
              className='vertical-timeline-element--work'
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            ></VerticalTimelineElement>
            <VerticalTimelineElement
              className='vertical-timeline-element--work'
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            ></VerticalTimelineElement>
            <VerticalTimelineElement
              className='vertical-timeline-element--work'
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            ></VerticalTimelineElement>
            <VerticalTimelineElement
              className='vertical-timeline-element--work'
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            ></VerticalTimelineElement>
          </VerticalTimeline>
            </div>
          )}
        </>
      ) : (
        <HomeLoader></HomeLoader>
      )}
    </div>
  )
}

export default HomePageForm
