import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import axios from 'axios';
import { BASE_URL, cookieFetcher } from '../../helper';
import { redirect } from 'react-router-dom';

// Validation schema
const validationSchema = Yup.object({
  catalogName: Yup.string().required('Required'),
  catalogSeoTitle: Yup.string().required('Required'),
  catalogSeoDesc: Yup.string().required('Required'),
  catalogSeoTags: Yup.string().required('Required'),
  featureImage: Yup.mixed().required('Required'),
  arrayImages: Yup.array().of(Yup.mixed()).required('Required')
});

const initialValues = {
  catalogName: '',
  catalogStatus: '',
  catalogSeoTitle: '',
  catalogSeoDesc: '',
  catalogSeoTags: '',
  featureImage: null,
  arrayImages: []
};

const handleSubmit = async (values, { setSubmitting }) => {
  await createCatalog(
    values.featureImage,
    values.arrayImages,
    values.catalogName,
    values.catalogStatus,
    values.catalogSeoTitle,
    values.catalogSeoDesc,
    values.catalogSeoTags
  );
  setSubmitting(false);
};

export const CreateCatalog = () => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, isSubmitting }) => (
        <Form>
          <div>
            <label htmlFor="catalogName">Catalog Name</label>
            <Field name="catalogName" type="text" />
            <ErrorMessage name="catalogName" component="div" />
          </div>
          <div>
            <label htmlFor="catalogStatus">Catalog Status</label>
            <Field name="catalogStatus" type="text" />
            <ErrorMessage name="catalogStatus" component="div" />
          </div>
          <div>
            <label htmlFor="catalogSeoTitle">SEO Title</label>
            <Field name="catalogSeoTitle" type="text" />
            <ErrorMessage name="catalogSeoTitle" component="div" />
          </div>
          <div>
            <label htmlFor="catalogSeoDesc">SEO Description</label>
            <Field name="catalogSeoDesc" type="text" />
            <ErrorMessage name="catalogSeoDesc" component="div" />
          </div>
          <div>
            <label htmlFor="catalogSeoTags">SEO Tags</label>
            <Field name="catalogSeoTags" type="text" />
            <ErrorMessage name="catalogSeoTags" component="div" />
          </div>
          <div>
            <label htmlFor="featureImage">Feature Image</label>
            <input
              name="featureImage"
              type="file"
              onChange={(event) => {
                setFieldValue("featureImage", event.currentTarget.files[0]);
              }}
            />
            <ErrorMessage name="featureImage" component="div" />
          </div>
          <div>
            <label htmlFor="arrayImages">Additional Images</label>
            <input
              name="arrayImages"
              type="file"
              multiple
              onChange={(event) => {
                setFieldValue("arrayImages", Array.from(event.currentTarget.files));
              }}
            />
            <ErrorMessage name="arrayImages" component="div" />
          </div>
          <button type="submit" disabled={isSubmitting}>
            Submit
          </button>
        </Form>
      )}
    </Formik>
  );
};

export const createCatalog = async (
  featureImage,
  arrayImages,
  catalogName,
  catalogStatus,
  catalogSeoTitle,
  catalogSeoDesc,
  catalogSeoTags
) => {
  // Basic client-side validation
  if (!catalogName || !catalogSeoTitle || !catalogSeoDesc || !catalogSeoTags) {
    Swal.fire({
      icon: 'error',
      title: 'Validation Error',
      text: 'Please fill in all required fields.',
    });
    return;
  }

  const formData = new FormData();

  if (featureImage) {
    // formData.append('photo', featureImage);
  }

  arrayImages.forEach(item => {
    if (item) {
      // formData.append('photo', item);
    }
  });

  formData.append('title', catalogName);
  formData.append('userId', cookieFetcher('vendorId'));
  formData.append('status', catalogStatus);
  formData.append('seo_meta_title', catalogSeoTitle);
  formData.append('seo_meta_desc', catalogSeoDesc);
  formData.append('seo_meta_tags', catalogSeoTags);

  try {
    const response = await axios.post(`${BASE_URL}/vendor/catalog/create`, formData, {
      headers: {
        'Authorization': `Bearer ${cookieFetcher('token')}`,
        'Content-Type': 'multipart/form-data',
      }
    });
  
    console.log('Response from createCatalog:', response);
  
    if (response.data.statusCode === 200) {
      Swal.fire({
        icon: 'success',
        title: 'Catalog Created',
        text: `Successfully Created ${response.data.data.title}!`,
      });
      redirect('/catalog');
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Failed to create catalog',
        text: `${response.data.statusCode} : ${response.data.message}`,
      });
    }
  } catch (error) {
    console.error('Error creating catalog:', error);
  
    let errorMessage = 'An error occurred while creating the catalog. Please try again later.';
    if (error.response && error.response.data) {
      console.error('Error response data:', error.response.data); // Added logging here
      errorMessage = `${error.response.data.statusCode} : ${error.response.data.message}`;
    }
  
    Swal.fire({
      icon: 'error',
      title: 'Failed to create catalog',
      text: errorMessage,
    });
  }
}