import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { cookieFetcher } from '../../../helper';
import Sidebar from '../../../components/Sidebar/Sidebar';
import OrdersHeader from '../../Orders/OrdersHeader';
import Footer from '../../../components/Footer/Footer';
import SliderDropdown from './SliderDropdown';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './style .css';

const SliderItem = ({ index, imageUrl, name, dateRange, active }) => (
  <tr>
    <td>{index}</td>
    <td className="slider-image">
      <img src={imageUrl}  />
      <span>{name}</span>
    </td>
    <td>Category Name</td>
    {/* <td>Price</td> */}
    <td>{dateRange}</td>
    <td className={`status ${active ? 'active' : 'inactive'}`}>{active ? 'Active' : 'Inactive'}</td>
    <td>
      <div className="dropdown">
         <SliderDropdown
                  // fetchStatus={fetchStatus}
                  // setFetchStatus={setFetchStatus}
                  // product={item}
                ></SliderDropdown>
      </div>
    </td>
  </tr>
);

const Slider = () => {
  const [sidebar, setSidebar] = useState(true);
  return (
    <div className="productsPage d-flex">
      <Helmet
        title="Slider"
        link={{ rel: "icon", type: "image/png", href: cookieFetcher("photo") }}
      ></Helmet>
      <Sidebar sidebar={sidebar} page={"home"}></Sidebar>
      <div className="homeMain">
        <div className="homeMainTop">
          <OrdersHeader
            sidebar={sidebar}
            setSidebar={setSidebar}
          ></OrdersHeader>
          <header className="slider-header">Slider</header>
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Product</th>
                <th>Category</th>
                {/* <th>Price</th> */}
                <th>Date</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <SliderItem
                index={1}
                imageUrl="path/to/image.jpg"
                name="Banana"
                dateRange="10/24 - 20/5/24"
                active={true}
              />
            </tbody>
          </table>
        </div>
        <div className="slider">
          <div className="bannerbtn">
            <button className="btn btn-primary">
              <a href="/banner" className="text-white">+</a>
            </button>
          </div>
        </div>
        <div className="homeMainBottom">
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
};

export default Slider;
