import React, { useEffect, useState } from 'react'
import '../Sidebar/Sidebar.css'
import SidebarHeader from './SidebarHeader'
import {
  BsShop,
  BsTools,
  BsChevronDown,
  BsChevronUp,
  BsDot,
} from 'react-icons/bs'
import { FiBell } from 'react-icons/fi'
import { FaBoxes } from 'react-icons/fa'
import { MdOutlineInventory } from 'react-icons/md'
import { FiSettings } from 'react-icons/fi'
import { AiFillCaretDown } from 'react-icons/ai'
import { PiSquaresFour } from 'react-icons/pi'
import { CgProfile } from 'react-icons/cg'
import { RiUserSettingsLine } from 'react-icons/ri'
import { redirect, useNavigate } from 'react-router-dom'
import { Collapse } from 'reactstrap'
import SmallSidebar from '../SmallSidebar/SmallSidebar'

function Sidebar({ product, page, sidebar }) {
  const iconColor = 'white'

  const [dropCheckInv, setDropCheckInv] = useState(false)
  const [dropCheckMan, setDropCheckMan] = useState(false)
  const [smallSidebar, setSmallSidebar] = useState(false)
  // const [sidebar, setSidebar] = useState(true)
  let navigate = useNavigate()

  //   const setter = (s) => {
  //     if (!dropCheckInv && !dropCheckMan && s === 'inv') {
  //       setDropCheckInv(true)
  //     } else if (!dropCheckInv && !dropCheckMan && s === 'man') {
  //       setDropCheckMan(true)
  //     } else if (!dropCheckInv && dropCheckMan) {
  //       setDropCheckInv(true)
  //       setDropCheckMan(false)
  //     } else if (!dropCheckMan && dropCheckInv) {
  //       setDropCheckMan(true)
  //       setDropCheckInv(false)
  //     }
  //   }
  useEffect(() => {
    if (product) {
      setDropCheckInv(true)
    }
  }, [])

  return (
    <div className='sidebarMain'>
      {/* <div className='sidebarLeft'>
        <div className='sidebarLeftTop'>
          <div className='sidebarButtonIcon'>
            {' '}
            <BsShop size={19}></BsShop>
          </div>
          <div className='sidebarButtonIcon'>
            {' '}
            <FaBoxes size={19}></FaBoxes>
          </div>
          <div className='sidebarButtonIcon'>
            {' '}
            <MdOutlineInventory size={19}></MdOutlineInventory>
          </div>
          <div
            className={
              !dropCheckInv ? 'sidebarButtonIcon' : 'sidebarButtonIconLower1'
            }
          >
            {' '}
            <BsTools size={19}></BsTools>
          </div>
          <div
            className={
              !dropCheckMan ? 'sidebarButtonIcon' : 'sidebarButtonIconLower2'
            }
          >
            <RiUserSettingsLine size={19}></RiUserSettingsLine>
          </div>
          <div className='sidebarButtonIcon'>
            {' '}
            <PiSquaresFour size={19}></PiSquaresFour>
          </div>
          <div className='sidebarButtonIcon'>
            {' '}
            <FiSettings size={19}></FiSettings>
          </div>
        </div>
      </div> */}
      {!sidebar && <SmallSidebar></SmallSidebar>}
      {sidebar && (
        <Collapse horizontal isOpen={sidebar}>
          <div className='sidebar'>
            <div>
              <SidebarHeader></SidebarHeader>
              <div className='sidebarNavPanel'>
                {/* <div
                className={
                  page === 'home' ? 'sidebarButton active' : 'sidebarButton'
                }
                // onClick={() => {
                //   setSidebar(!sidebar)
                // }}
              >
                <div className='sidebarButtonIcon'>
                  {' '}
                  <BsShop size={19}></BsShop>
                </div>

                <div className='buttonText'>Toggle</div>
              </div> */}
                <div
                  className={
                    page === 'home' ? 'sidebarButton active' : 'sidebarButton'
                  }
                  onClick={() => {
                    navigate('/')
                  }}
                >
                  <div className='sidebarButtonIcon'>
                    {' '}
                    {/* <BsShop size={19}></BsShop> */}
                    <span class="material-symbols-outlined">
                      home
                    </span>
                  </div>

                  <div className='buttonText'>Home</div>
                </div>
                <div
                  onClick={() => {
                    navigate('/orders')
                  }}
                  className={
                    page === 'orders' ? 'sidebarButton active' : 'sidebarButton'
                  }
                >
                  <div className='sidebarButtonIcon'>
                    {/* <FaBoxes size={19}></FaBoxes> */}
                    <span class="material-symbols-outlined">
                      package_2
                    </span>
                  </div>
                  <div className='buttonText' color='white'>
                    Orders
                  </div>
                </div>
                <div
                
                  className={
                    page === 'catalogs' || page === 'products'
                      ? 'sidebarButton inventorySpecial active'
                      : 'sidebarButton inventorySpecial'
                      
                  }
                  
                  onClick={() => 
                    {
                       if (!dropCheckInv & dropCheckMan) {
                        setDropCheckInv(true)
                        setDropCheckMan(false)
                      } else if (dropCheckInv) {
                        setDropCheckInv(false)
                      } else if (!dropCheckInv) {
                        setDropCheckInv(true)
                      }

                    }
                  }
                >
                  <div
                    className='d-flex inventoryButton'
                    onClick={() => {
                      if (!dropCheckInv & dropCheckMan) {
                        setDropCheckInv(true)
                        setDropCheckMan(false)
                      } else if (dropCheckInv) {
                        setDropCheckInv(false)
                      } else if (!dropCheckInv) {
                        setDropCheckInv(true)
                      }
                    }}
                    // size={10}
                  >
                    <div className='sidebarButtonIcon'>
                      {/* <MdOutlineInventory size={19}></MdOutlineInventory> */}
                      <span class="material-symbols-outlined">
                        receipt_long
                      </span>
                    </div>
                    <div className='buttonText' color='white'>
                      Catalog
                    </div>
                  </div>
                  {dropCheckInv ? (
                    <BsChevronUp
                      className='mt-2 m-lg-2'
                      size={10}
                      color={iconColor}
                      onClick={() => {
                        setDropCheckInv(false)
                      }}
                    ></BsChevronUp>
                  ) : (
                    <BsChevronDown
                      className='mt-2 m-lg-2'
                      size={10}
                      color={iconColor}
                      onClick={() => {
                        setDropCheckInv(true)
                        setDropCheckMan(false)
                      }}
                      ></BsChevronDown>
                    )}
                
                </div>
                {dropCheckInv ? (
                  <div className='inventoryDropDown'>
                    <div
                      className='inventoryDDButton '
                      onClick={() => {
                        navigate('/products')
                      }}
                    >
                      <BsDot color='white'></BsDot>

                      <div className='iddBName'>Products</div>
                    </div>
                    <div
                      className='inventoryDDButton'
                      onClick={() => {
                        navigate('/category')
                      }}
                    >
                      <BsDot color='white'></BsDot>
                      <div className='iddBName'>Category</div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div
                  className={
                    page === 'tools'
                      ? 'sidebarButton inventorySpecial active'
                      : 'sidebarButton inventorySpecial'
                  }
                  onClick={() => {
                    navigate('/tools')
                  }}
                >
                  <div
                    className='d-flex inventoryButton'
                    onClick={() => {
                      if (!dropCheckMan & dropCheckInv) {
                        console.log('Hi')
                        setDropCheckMan(true)
                        setDropCheckInv(false)
                      } else if (dropCheckMan) {
                        setDropCheckMan(false)
                      } else if (!dropCheckMan) {
                        setDropCheckMan(true)
                      }
                    }}
                  >
                    <div className='sidebarButtonIcon'>
                      {/* <BsTools size={19}></BsTools> */}
                      <span class="material-symbols-outlined">
                        build
                      </span>
                    </div>
                    <div className='buttonText manageButton' color='white'>
                      Tools
                    </div>
                  </div>
                  {/* {dropCheckMan ? (
                    <BsChevronUp
                      className='mt-2'
                      size={10}
                      color={iconColor}
                      onClick={() => {
                        setDropCheckMan(false)
                      }}
                    ></BsChevronUp>
                  ) : (
                    <BsChevronDown
                      className='mt-2'
                      size={10}
                      color={iconColor}
                      onClick={() => {
                        setDropCheckMan(true)
                        setDropCheckInv(false)
                      }}
                    ></BsChevronDown>
                  )} */}
                </div>

                {dropCheckMan ? (
                  //   <div className='inventoryDropDown'>
                  //     <div className='inventoryDDButton'>
                  //       <BsDot color='white'></BsDot>
                  //       <div className='iddBName'>OR Code</div>
                  //     </div>
                  //   </div>

                  // )
                  <></>
                ) : (
                  <></>
                )}
                <div
                  className={
                    page === 'customer'
                      ? 'sidebarButton  active'
                      : 'sidebarButton'
                  }
                  onClick={() => {
                    navigate('/customers')
                  }}
                >
                  <div className='sidebarButtonIcon'>
                    {/* <RiUserSettingsLine size={19}></RiUserSettingsLine> */}
                    <span class="material-symbols-outlined">
                      manage_accounts
                    </span>
                  </div>
                  <div className='buttonText' color='white'>
                    Customer
                  </div>
                </div>
                <div
                  className={
                    page === 'couponCode'
                      ? 'sidebarButton active'
                      : 'sidebarButton'
                  }
                  onClick={() => {
                    navigate('/couponCode')
                  }}
                >
                  <div className='sidebarButtonIcon'>
                    {/* <PiSquaresFour size={19}></PiSquaresFour> */}
                    <span class="material-symbols-outlined">
                      qr_code
                    </span>
                  </div>

                  <div className='buttonText' color='white'>
                    Coupon Code
                  </div>
                </div>
                <div
                  className={
                    page === 'settings'
                      ? 'sidebarButton active'
                      : 'sidebarButton'
                  }
                  onClick={() => {
                    navigate('/settings')
                  }}
                >
                  <div className='sidebarButtonIcon'>
                    {/* <FiSettings size={19}></FiSettings> */}
                    <span class="material-symbols-outlined">
                      settings
                    </span>
                  </div>

                  <div className='buttonText' color='white'>
                    Setting
                  </div>
                </div>
                {/* <div
            className='sidebarButton'
            onClick={() => {
              navigate('/profile')
            }}
          >
            <CgProfile size={19} color={iconColor}></CgProfile>
            <div className='buttonText' color='white'>
              Profile
            </div>
          </div> */}
              </div>
            </div>
            <div style={{ display: 'flex', gap: '10px', padding: '5px 10px' }}>
              <div
                className='docsAndComps'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  window.open('https://help.lofaz.com/', '_blank')
                }}
              >
                Help
              </div>
              <div
                className='docsAndComps'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  window.location.href = 'javascript:void(Tawk_API.toggle())'
                }}
              >
                Support
              </div>
            </div>
          </div>
        </Collapse>
      )}
    </div>
  )
}

export default Sidebar
