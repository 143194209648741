import { BASE_URL, cookieFetcher } from '../../helper'
import Swal from 'sweetalert2'

const profileEditor = async (
  profileImage,
  username,
  phoneNumber,
  businessName,
  businessCategory,
  fullName,
  email,
  addLine1,
  addLine2,
  city,
  state,
  country,
  zip
) => {
  const formdata = new FormData()

  formdata.append('username', username)
  formdata.append('phone', phoneNumber)
  formdata.append('business_name', businessName)
  formdata.append('account_type', businessCategory)
  formdata.append('username_lowercase', username.toLowerCase())
  formdata.append('photo', profileImage)
  formdata.append('full_name', fullName)
  formdata.append('email', email)
  formdata.append('address_line1', addLine1)
  formdata.append('address_line2', addLine2)
  formdata.append('country', country)
  formdata.append('city', city)
  formdata.append('state', state)
  formdata.append('zip', zip)
  const response = await fetch(`${BASE_URL}/vendor`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${cookieFetcher('token')}`,
    },
    body: formdata,
  })
  const res = await response.json()
  if (response.status === 200) {
    Swal.fire({
      icon: 'success',
      title: 'Yay',
      text: 'Values Update Successfully.',
    });
  } else {
    Swal.fire({
      icon: 'error',
      title: 'Something has gone wrong',
      text: 'Failed to update values.',
    });
  }
  console.log(res)
} 
// }
export default profileEditor
