import React, { useEffect, useState } from 'react'
import './OrderDetails.css'
import { BASE_URL, cookieFetcher, loginChecker } from '../../helper'
import { useParams } from 'react-router-dom'
import { Toast } from 'reactstrap'
import { toast } from 'react-hot-toast'
import Swal from 'sweetalert2'
import Sidebar from '../../components/Sidebar/Sidebar'
import OrderDetailsMain from './OrderDetailsMain'
import { Helmet } from 'react-helmet'

function OrderDetails() {
  const { orderId } = useParams()
  const [order, setOrder] = useState([])
  const [subtotal, setSubtotal] = useState(0)

  useEffect(() => {
    loginChecker()
    const fetcher = async () => {
      const response = await fetch(
        `${BASE_URL}/customer/orders_details/${orderId}`,
        {
          headers: {
            Authorization: `Bearer ${cookieFetcher('token')}`,
          },
          method: 'GET',
        }
      )
      const res = await response.json()
      if (res.status === 200) {
        setOrder(res.data)
        let value = 0
        res.data.map((item) => {
          item.shipping_details.product.map((item2) => {
            value = value + item2.total
          })
        })
        setSubtotal(value)
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong!',
          text: `${res.status}: ${res.message}`,
        })
      }
    }
    fetcher()
  }, [])
  const [sidebar, setSidebar] = useState(true)
  return (
    <div className='orderDetailsPage'>
      <Helmet
        title='Order Details'
        link={{ rel: 'icon', type: 'image/png', href: cookieFetcher('photo') }}
      ></Helmet>
      <Sidebar sidebar={sidebar}></Sidebar>
      <OrderDetailsMain
        order={order[0] && order[0]}
        sidebar={sidebar}
        setSidebar={setSidebar}
        subtotal={subtotal}
      ></OrderDetailsMain>
    </div>
  )
}

export default OrderDetails
