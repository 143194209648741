import React, { useState } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faShare, faTrash } from '@fortawesome/free-solid-svg-icons';
import ShareModal from './ShareModal'; // Import the ShareModal component

import Swal from 'sweetalert2'
import { BASE_URL, cookieFetcher } from '../../helper'
import { useNavigate } from 'react-router-dom'


function ProductsDropDown({ product, fetchStatus, setFetchStatus }) {
  // console.log('product dropdown', product.url);
  const [showShareModal, setShowShareModal] = useState(false);

  const shareUrl = `https://lofaz.in/test23/category/lodaz,test2/product/${product.url}`;

  const handleShareClick = () => {
    setShowShareModal(true);
  };

  const closeModal = () => {
    setShowShareModal(false);
  };
  const deleting = async (product) => {
    const res = await fetch(`${BASE_URL}/vendor/product/${product._id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${cookieFetcher('token')}`,
      },
    })
    const response = await res.json()
    if (response.status === 200) {
      setFetchStatus(!fetchStatus)
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${response.message}`,
      })
    }
  }
  const productDeleter = async (product) => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: `Are you sure you want to delete ${product.title}?`,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleting(product)
      }
    })
  }

  return (
    <UncontrolledDropdown>
      <DropdownToggle
        style={{
          background: 'rgb(249, 249, 249)',
          color: 'rgb(126, 130, 153)',
          border: 'none',
          fontSize: '12px',
          fontWeight: '600',
        }}
        caret
      >
        Actions
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          onClick={() => {
            window.location.href = `/products/edit/${product._id}`;
          }}
          style={{ display: 'flex', gap: '7px' }}
        >
          <FontAwesomeIcon icon={faEdit} />
          Edit
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            productDeleter(product)
            // Implement product deletion logic here
          }}
          style={{ display: 'flex', gap: '7px' }}
        >
          <FontAwesomeIcon icon={faTrash} />
          Delete
        </DropdownItem>
        <DropdownItem
          onClick={handleShareClick}
          style={{ display: 'flex', gap: '7px' }}
        >
          <FontAwesomeIcon icon={faShare} />
          Share
        </DropdownItem>
      </DropdownMenu>

      {showShareModal && (
        <ShareModal shareUrl={shareUrl} onClose={closeModal} />
      )}
    </UncontrolledDropdown>
  );
}

export default ProductsDropDown;
