import CatalogsDropDown from '../../components/Dropdowns/CatalogsDropDown'
import { defaultLogo } from '../../helper'

function capitalize(str) {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const sortByStatus = (objects) => {
  const activeObjects = objects.filter((obj) => obj.active === true)
  const inactiveObjects = objects.filter((obj) => obj.active === false)

  return activeObjects.concat(inactiveObjects)
}

export const sortObjectsByName = (objects) => {
  return objects.sort((a, b) => {
    const nameA = a.title.toLowerCase()
    const nameB = b.title.toLowerCase()

    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })
}

export const sortByCount = (arr) => {
  const newArr = arr.sort((a, b) => b.totalProduct - a.totalProduct)
  return newArr
}

export const SortedStatus = ({ catalogs, setFetchStatus, paginatedCatalog,fetchStatus,currentPage,catloglimit, }) => {
  return (
    <>
      {paginatedCatalog
        .sort((a, b) => (a.active === b.active ? 0 : a.active ? -1 : 1))
        .map((item, index) => {
          return (
            <tr>
              <th scope='row'>{(currentPage - 0) *  catloglimit  + index + 1}</th>
              <td
                className='catalogImageAndTitle'
                style={{
                  display: 'flex',
                  paddingLeft: '30px',
                  gap: '15px',
                }}
              >
                <div>
                  <img
                    src={
                      item.photo && item.photo[0]
                        ? item.photo[0].location
                        : `${defaultLogo}`
                    }
                    style={{
                      width: '35px',
                      height: '35px',
                      borderRadius: '5px',
                    }}
                  ></img>
                </div>
                <div style={{ paddingTop: '5px' }}>
                  {capitalize(item?.title)}
                </div>
              </td>
              <td className='countOfProducts'>{item.totalProduct}</td>
              <td className='catalogStatus'>
                {item.active ? 'Active' : 'Inactive'}
              </td>
              <td></td>
              <td style={{ textAlign: 'start' }}>
                <CatalogsDropDown
                  id={item._id}
                  fetchStatus={fetchStatus}
                  catalog={item}
                  setFetchStatus={setFetchStatus}
                ></CatalogsDropDown>
              </td>
            </tr>
          )
        })}
    </>
  )
}

export const SortedName = ({ catalogs, setFetchStatus, paginatedCatalog,fetchStatus,currentPage,catloglimit }) => {
  return (
    <>
      {paginatedCatalog
        .sort((a, b) => {
          const nameA = a.title.toLowerCase()
          const nameB = b.title.toLowerCase()

          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
        .map((item, index) => {
          return (
            <tr>
              <th scope='row'>{(currentPage - 0) *  catloglimit  + index + 1}</th>
              <td
                className='catalogImageAndTitle'
                style={{
                  display: 'flex',
                  paddingLeft: '30px',
                  gap: '15px',
                }}
              >
                <div>
                  <img
                    src={
                      item.photo && item.photo[0]
                        ? item.photo[0].location
                        : `${defaultLogo}`
                    }
                    style={{
                      width: '35px',
                      height: '35px',
                      borderRadius: '5px',
                    }}
                  ></img>
                </div>
                <div style={{ paddingTop: '5px' }}>
                  {capitalize(item?.title)}
                </div>
              </td>
              <td className='countOfProducts'>{item.totalProduct}</td>
              <td className='catalogStatus'>
                {item.active ? 'Active' : 'Inactive'}
              </td>
              <td></td>
              <td style={{ textAlign: 'start' }}>
                <CatalogsDropDown
                  id={item._id}
                  fetchStatus={fetchStatus}
                  catalog={item}
                  setFetchStatus={setFetchStatus}
                ></CatalogsDropDown>
              </td>
            </tr>
          )
        })}
    </>
  )
}

export const SortedCount = ({ catalogs, setFetchStatus, paginatedCatalog,fetchStatus,currentPage,catloglimit }) => {
  return (
    <>
      {paginatedCatalog
        .sort((a, b) => b.totalProduct - a.totalProduct)
        .map((item, index) => {
          return (
            <tr>
              <th scope='row'>{(currentPage - 0) *  catloglimit  + index + 1}</th>
              <td
                className='catalogImageAndTitle'
                style={{
                  display: 'flex',
                  paddingLeft: '30px',
                  gap: '15px',
                }}
              >
                <div>
                  <img
                    src={
                      item.photo && item.photo[0]
                        ? item.photo[0].location
                        : `${defaultLogo}`
                    }
                    style={{
                      width: '35px',
                      height: '35px',
                      borderRadius: '5px',
                    }}
                  ></img>
                </div>
                <div style={{ paddingTop: '5px' }}>
                  {capitalize(item?.title)}
                </div>
              </td>
              <td className='countOfProducts'>{item.totalProduct}</td>
              <td className='catalogStatus'>
                {item.active ? 'Active' : 'Inactive'}
              </td>
              <td></td>
              <td style={{ textAlign: 'start' }}>
                <CatalogsDropDown
                  id={item._id}
                  fetchStatus={fetchStatus}
                  catalog={item}
                  setFetchStatus={setFetchStatus}
                ></CatalogsDropDown>
              </td>
            </tr>
          )
        })}
    </>
  )
}

export const SortedDate = ({ catalogs, setFetchStatus, paginatedCatalog,fetchStatus,currentPage,catloglimit }) => {
  return (
    <>
      {paginatedCatalog
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .map((item, index) => {
          return (
            <tr>
              <th scope='row'>{(currentPage - 0) *  catloglimit  + index + 1}</th>
              <td
                className='catalogImageAndTitle'
                style={{
                  display: 'flex',
                  paddingLeft: '30px',
                  gap: '15px',
                }}
              >
                <img
                  src={
                    item.photo && item.photo[0]
                      ? item.photo[0].location
                      : `${defaultLogo}`
                  }
                  style={{
                    width: '35px',
                    height: '35px',
                    borderRadius: '5px',
                  }}
                ></img>
                {capitalize(item?.title)}
              </td>
              <td className='countOfProducts'>{item.totalProduct}</td>
              <td className='catalogStatus'>
                {item.active ? 'Active' : 'Inactive'}
              </td>
              <td></td>
              <td style={{ textAlign: 'start' }}>
                <CatalogsDropDown
                  id={item._id}
                  fetchStatus={fetchStatus}
                  catalog={item}
                  setFetchStatus={setFetchStatus}
                ></CatalogsDropDown>
              </td>
            </tr>
          )
        })}
    </>
  )
}
