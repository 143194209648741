import React, { useEffect } from 'react'
import './EditProfileForm.css'
import { useState } from 'react'
import ReactQuill from 'react-quill'
import NavigationBoxSettings from '../NavigationBox/NavigationBoxSettings'
import StoreStatusForm from './OtherProfileForms/StoreStatusForm'
import PrivacyPolicyForm from './OtherProfileForms/PrivacyPolicyForm'
import SocialMediaForm from './OtherProfileForms/SocialMediaForm'
import StoreTimingForm from './OtherProfileForms/StoreTimingForm'
import ShippingAndDeliveryForm from './OtherProfileForms/ShippingAndDeliveryForm'
import ShippingAndDeliverySettings from '../../pages/Setting/ShippingandDeliverySetting'
import { BASE_URL, cookieFetcher, defaultFeatureImage } from '../../helper'
import { useRef } from 'react'
import profileEditor from './ProfileEditor'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import Swal from 'sweetalert2'
import Coming_soon from '../../pages/Setting/Coming_soon'


function EditProfileForm({ type }) {
  const [profileImage, setProfileImage] = useState(
    'https://res.cloudinary.com/nikhil-patil-web/image/upload/v1688966827/LofazInputImage_narqjv.png'
  )
  const [profileImageLink, setProfileImageLink] = useState(
    'https://res.cloudinary.com/nikhil-patil-web/image/upload/v1688966827/LofazInputImage_narqjv.png'
  )

  const [username, setUsername] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [businessCategory, setBusinessCategory] = useState('')
  const [businessName, setBusinessName] = useState('')
  const [email, setEmail] = useState('')
  const [country, setCountry] = useState('India')
  const [state, setState] = useState('')
  const [fullName, setFullName] = useState('')
  const [city, setCity] = useState('')
  const [zip, setZip] = useState('')
  const [addLine1, setAddLine1] = useState('')
  const [addLine2, setAddLine2] = useState('')
  const [uNedit, setUNEdit] = useState(true)
  const [pNEdit, setPNEdit] = useState(true)
  const [bcEdit, setBCEdit] = useState(true)
  const [bNEdit, setBNEdit] = useState(true)
  const [sucess,setsucess]= useState(false)

  const inputFile = useRef()
  // const inputFile = useRef(n ull);

  useEffect(() => {
    const fetcher = async () => {
      const response = await fetch(
        `${BASE_URL}/vendor/visitor/${cookieFetcher('username')}`
      )
      const res = await response.json()
      
      console.log(res)
      // console.log(res.data.photo.length)
      setUsername(res.data.username)
      setPhoneNumber(res.data.phone)
      setBusinessCategory(res.data.account_type)
      setBusinessName(res.data.business_name)
      setFullName(res.data.full_name)
      setCountry(res.data.country)
      setZip(res.data.zip)
      setCity(res.data.city)
      setAddLine1(res.data.address_line1)
      setAddLine2(res.data.address_line2)
      if (res.data.photo.length > 0) {
        setProfileImageLink(res.data.photo)
      }
      if (res.data.photo.length > 0) {
        setProfileImage(res.data.photo)
      }
    }
    fetcher()
  }, [])

  const onButtonClick = async () => {

    
    inputFile.current.click();
  };

 

  return (
    <>
      <NavigationBoxSettings photo={profileImageLink}></NavigationBoxSettings>
      {type === 'profile' && (
        <div className='editProfileForm'>
          <div className='editProfileFormSection'>
            <div className='editProfileSecTitle'>Business Details</div>
            <div className='editProfileSecContent'>
              <div
                className='profileImageAdder'
                onClick={() => {
                  onButtonClick()
                }}
              >
                <img
                  src={profileImageLink}
                  width='150px'
                  height='150px'
                  style={{ borderRadius: '7px', border: '1px dashed #009ef7', objectFit: 'contain', }}
                ></img>
                <input
                  onChange={(e) => {
                    if (e.target.files) {
                      setProfileImage(e.target.files[0])
                      setProfileImageLink(
                        URL.createObjectURL(e.target.files[0])
                      )
                    }
                  }}
                  type='file'
                  ref={inputFile}
                  style={{ display: 'none' }}
                ></input>
              </div>
              <div className='profileDetailsAdder'>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                  }}
                >
                  <div className='usernameAdder'>
                    <div className='profileInputLabel'>
                      <p style={{ color: '#1e1e2d' }}>Username</p>
                      <p
                        style={{ color: '#009ef7', cursor: 'pointer' }}
                        onClick={() => {
                          setUNEdit(!uNedit)
                        }}
                      >
                        {!uNedit ? 'Save' : 'Edit'}
                      </p>
                    </div>
                    <input
                      onChange={(e) => {
                        setUsername(e.target.value)
                      }}
                      value={username}
                      placeholder='Username....'
                      disabled={uNedit}
                    ></input>
                  </div>
                    <div className='phoneAdder'>
                      <div className='profileInputLabel'>
                        <p style={{ color: '#1e1e2d' }}>Business Name</p>
                        <p
                          style={{ color: '#009ef7', cursor: 'pointer' }}
                          onClick={() => {
                            setBNEdit(!bNEdit)
                          }}
                        >
                          {!bNEdit ? 'Save' : 'Edit'}
                        </p>
                      </div>
                      <input
                        onChange={(e) => {
                          setBusinessName(e.target.value)
                        }}
                        value={businessName}
                        placeholder='Business Name....'
                        disabled={bNEdit}
                      ></input>
                    </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                  }}
                >
                  <div className='businessNameAdder'>
                    <div className='profileInputLabel'>
                      <p style={{ color: '#1e1e2d' }}>Phone Number</p>
                      {/* <p
                        style={{ color: '#009ef7', cursor: 'pointer' }}
                        onClick={() => {
                          setPNEdit(!pNEdit)
                        }}
                      >
                        {!pNEdit ? 'Save' : 'Edit'}
                      </p> */}
                    </div>
                    <input
                      onChange={(e) => {
                        setPhoneNumber(e.target.value)
                      }}
                      value={phoneNumber}
                      placeholder='Phone Number....'
                      disabled={pNEdit}
                    ></input>
                  </div>
                  <div className='categoryAdder'>
                    <div className='profileInputLabel'>
                      <p style={{ color: '#1e1e2d' }}>Category</p>
                      <p
                        style={{ color: '#009ef7', cursor: 'pointer' }}
                        onClick={() => {
                          setBCEdit(!bcEdit)
                        }}
                      >
                        {!bcEdit ? 'Save' : 'Edit'}
                      </p>
                    </div>
                    <input
                      onChange={(e) => {
                        setBusinessCategory(e.target.value)
                      }}
                      value={businessCategory}
                      placeholder='Category....'
                      disabled={bcEdit}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='editProfileFormSection'>
            <div className='editProfileSecTitle'>Information</div>
            <div className='editProfileSecContent2'>
              <div
                className='line1EditSection'
                style={{ display: 'flex', gap: '20px' }}
              >
                <div className='firstNameAdder'>
                  <p>First Name</p>
                  <input placeholder='First Name....'></input>
                </div>
                <div className='lasttNameAdder'>
                  <p>Last Name</p>
                  <input
                    value={fullName}
                    onChange={(e) => {
                      setFullName(e.target.value)
                    }}
                    placeholder='Last Name....'
                  ></input>
                </div>
                <div className='mailAdder'>
                  <p>Email Id</p>
                  <input
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value)
                    }}
                    placeholder='Email Id....'
                  ></input>
                </div>
              </div>
              <div className=''>
                <div className='aboutStoreTitle'>About Store</div>
                <ReactQuill
                  theme='snow'
                  style={{
                    textAlign: 'left',
                    color: '#a1a5b7',
                  }}
                  placeholder='Type your text here........'
                ></ReactQuill>
                 <div
                    style={{
                      fontSize: '12px',
                      color: '#a1a5b7',
                      fontWeight: '400',
                      textAlign: 'left',
                    }}
                  >
                    Max 1000 words
                  </div>
                
                
              </div>
              
            </div>
          </div>
          <div className='editProfileFormSection'>
            <div className='editProfileSecTitle'>Address</div>
            <div className='editProfileSecContent2'>
              <div className='line'>
                <div className='inputAndLabel'>
                  <div className='label'>Address Line 1</div>
                  <input
                    value={addLine1}
                    onChange={(e) => {
                      setAddLine1(e.target.value)
                    }}
                  ></input>
                </div>
                <div className='inputAndLabel'>
                  <div className='label'>Address Line 2</div>
                  <input
                    value={addLine2}
                    onChange={(e) => {
                      setAddLine2(e.target.value)
                    }}
                  ></input>
                </div>
                <div className='inputAndLabel'>
                  <div className='label'>Country</div>
                  <CountryDropdown
                    value={country}
                    className='countryDropdown'
                    onChange={(e) => setCountry(e)}
                  ></CountryDropdown>
                </div>
              </div>
              <div className='line'>
                {/* <div className='inputAndLabel'>
                  <div className='label'>Country</div>
                  <CountryDropdown
                    value={country}
                    className='countryDropdown'
                    onChange={(e) => setCountry(e)}
                  ></CountryDropdown>
                </div> */}
                <div className='inputAndLabel'>
                  <div className='label'>State</div>
                  <RegionDropdown
                    className='stateDropdown'
                    country={country}
                    value={state}
                    onChange={(e) => {
                      setState(e)
                    }}
                  ></RegionDropdown>
                </div>
                <div className='inputAndLabel'>
                  <div className='label'>City</div>
                  <input
                    onChange={(e) => {
                      setCity(e.target.value)
                    }}
                    value={city}
                  ></input>
                </div>
                <div className='inputAndLabel'>
                  <div className='label'>Zip</div>
                  <input
                    onChange={(e) => {
                      setZip(e.target.value)
                    }}
                    value={zip}
                  ></input>
                </div>
              </div>
              {/* <div className='line'>
                <div className='inputAndLabel'>
                  <div className='label'>City</div>
                  <input
                    onChange={(e) => {
                      setCity(e.target.value)
                    }}
                    value={city}
                  ></input>
                </div>
                <div className='inputAndLabel'>
                  <div className='label'>Zip</div>
                  <input
                    onChange={(e) => {
                      setZip(e.target.value)
                    }}
                    value={zip}
                  ></input>
                </div>
              </div> */}
            </div>
          </div>
          <div className='cancelAndSaveButton'>
            {/* <div className='cancelButton'>Cancel</div> */}
            <div
              className='saveButton'
              onClick={() => {
                profileEditor(
                  profileImage,
                  username,
                  phoneNumber,
                  businessName,
                  businessCategory,
                  fullName,
                  email,
                  addLine1,
                  addLine2,
                  city,
                  state,
                  country,
                  zip
                )
              }}
            >
              Save Change 
            </div>
          </div>
        </div>
      )}
      {type === 'storeStatus' && <StoreStatusForm Form></StoreStatusForm>}
      {type === 'privacyPolicy' && <Coming_soon></Coming_soon>}
      {type === 'socialMedia' && <Coming_soon></Coming_soon>}
      {type === 'storeTiming' && <Coming_soon></Coming_soon>}
       {type === 'shippingAndDelivery' && <Coming_soon></Coming_soon>}
       {type === 'warehouse' && <Coming_soon></Coming_soon>}
      
      {/* {type === 'privacyPolicy' && <PrivacyPolicyForm></PrivacyPolicyForm>} */}
      {/* {type === 'socialMedia' && <SocialMediaForm></SocialMediaForm>} */}
      {/* {type === 'storeTiming' && <StoreTimingForm></StoreTimingForm>} */}
      {/* {type === 'shippingAndDelivery' && (
        <ShippingAndDeliveryForm></ShippingAndDeliveryForm> */}
      {/* )} */}
    </>
  )
}

export default EditProfileForm
