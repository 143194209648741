import React, { useEffect, useState } from 'react'
import { Table } from 'reactstrap'
import { BASE_URL, cookieFetcher, loginChecker } from '../../helper'
import OrdersDropdown from '../../components/Dropdowns/OrdersDropdown'
import { useHref, useNavigate } from 'react-router-dom'
import ListLoader from '../../components/FormLoader/ListLoader'
// import ListLoader from '../../components/FormLoader/ListLoader'
import { BsSearch } from 'react-icons/bs'
import ReactPaginate from 'react-paginate';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap'
function OrdersMain() {
  const [orderLimit, setOrderLimit] = useState(10);
  const [orders, setOrders] = useState([]);
  const [vendorId, setVendorId] = useState(cookieFetcher("vendorId"));
  const [listLoader, setListLoader] = useState(false);
  const [allOrders, setAllOrders] = useState([]);
  const [filterName, setFilterName] = useState("All");
  const [allCount, setAllCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [acceptedCount, setAcceptedCount] = useState(0);
  const [shippedCount, setShippedCount] = useState(0);
  const [dCount, setDCount] = useState(0);
  const [cCount, setCCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  function countStatusObjects(arr) {
    let pendingCount = 0;
    let deliveredCount = 0;
    let cancelCount = 0;
    let shippedCount = 0;
    let acceptCount = 0;

    for (const obj of arr) {
      if (obj.pending_status) pendingCount++;
      if (obj.deliver_status) deliveredCount++;
      if (obj.cancel_status) cancelCount++;
      if (obj.shipped_status) shippedCount++;
      if (obj.accept_status) acceptCount++;
    }

    setPendingCount(pendingCount);
    setDCount(deliveredCount);
    setCCount(cancelCount);
    setShippedCount(shippedCount);
    setAcceptedCount(acceptCount);
  }

  const navigate = useNavigate();

  const filterOrders = (status) => {
    let toBeReturn = [];
    if (status === "cancelled") {
      toBeReturn = allOrders.filter((item) => item.cancel_status === true);
      setFilterName("Cancel");
    } else if (status === "pending") {
      toBeReturn = allOrders.filter((item) => item.pending_status === true);
      setFilterName("Pending");
    } else if (status === "accepted") {
      toBeReturn = allOrders.filter((item) => item.accept_status === true);
      setFilterName("Accept");
    } else if (status === "delivered") {
      toBeReturn = allOrders.filter((item) => item.deliver_status === true);
      setFilterName("Deliver");
    } else if (status === "shipped") {
      toBeReturn = allOrders.filter((item) => item.shipped_status === true);
      setFilterName("Ship");
    }
    return toBeReturn;
  };

  useEffect(() => {
    loginChecker();
    const fetcher = async () => {
      setListLoader(true);
      const res = await fetch(
        `${BASE_URL}/customer/order/get_all_order/${vendorId}`
      );
      const response = await res.json();
      if (response.data) {
        countStatusObjects(response.data);
        setOrders(response.data);
        setAllCount(response.data.length);
        setAllOrders(response.data);
      }
      setListLoader(false);
    };
    fetcher();
  }, [vendorId]);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  function formatDateFromMongoDB(createdAt) {
    const date = new Date(createdAt);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const searchObjectsByField = (searchString, arrayOfObjects) => {
    const trimmedString = searchString.trim().toLowerCase();
    if (trimmedString.length === 0) {
      setOrders(allOrders);
    } else {
      const searchedObjects = arrayOfObjects.filter((obj) => {
        const customerName = obj.shipping_name
          ? obj.shipping_name.toLowerCase()
          : "";
        const orderId = obj._id ? obj._id.toString() : "";
        return (
          customerName.includes(trimmedString) ||
          orderId.includes(trimmedString)
        );
      });
      setOrders(searchedObjects);
    }
  };

  const pageCount = orders.length ? Math.ceil(orders.length / orderLimit) : 0;
  const paginatedOrders = orders.length
    ? orders.slice(currentPage * orderLimit, (currentPage + 1) * orderLimit)
    : [];

  return (
    <>
      {!listLoader ? (
        <div className="ordersMain">
          <div className="d-flex justify-content-between">
            <div className="omTopLeft">
              <div className="oSearch p-1">
                <div className="searchIcon">
                  <BsSearch size={25} />
                </div>
                <div className="searchText">
                  <input
                    onChange={(e) =>
                      searchObjectsByField(e.target.value, allOrders)
                    }
                    type="text"
                    placeholder="Search Product"
                  />
                </div>
              </div>
            </div>
            <div className="orderFilterList">
              {[
                { name: "All", count: allCount },
                { name: "Pending", count: pendingCount, status: "pending" },
                { name: "Accepted", count: acceptedCount, status: "accepted" },
                { name: "Shipped", count: shippedCount, status: "shipped" },
                { name: "Delivered", count: dCount, status: "delivered" },
                { name: "Cancelled", count: cCount, status: "cancelled" },
              ].map(({ name, count, status }) => (
                <div
                  key={name}
                  className={
                    filterName !== name
                      ? "orderFilterListButton"
                      : "orderListButtonActive"
                  }
                  onClick={() => {
                    setFilterName(name);
                    setOrders(status ? filterOrders(status) : allOrders);
                  }}
                >
                  <span>{name}</span>
                  <div className="countShower">{count}</div>
                </div>
              ))}
            </div>
            <div className="omTopRight">
              {/* <UncontrolledDropdown>
                <DropdownToggle className="filetrBtn d-flex align-items-center">
                  <span className="material-symbols-outlined">filter_alt</span>
                  Filter
                </DropdownToggle>
                <DropdownMenu className="filter_dropdown">
                  <DropdownItem>Sort By Date</DropdownItem>
                  <DropdownItem>Sort By Name</DropdownItem>
                  <DropdownItem>Sort By Price</DropdownItem>
                  <DropdownItem>Sort By Status</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}
            </div>
          </div>
          <Table
            style={{ fontFamily: "Poppins, sans-serif", marginTop: "20px" }}
            responsive
          >
            <thead>
              <tr>
                <th className="text-center">#</th>
                <th className="text-center">ORDER ID</th>
                <th className="text-center">DATE</th>
                <th className="text-center">CUSTOMER NAME</th>
                <th className="text-center">COUNT</th>
                <th className="text-center">TOTAL</th>
                <th className="text-center">ACTION</th>
              </tr>
            </thead>
            <tbody>
              {paginatedOrders.map((item, index) => (
                <tr key={index}>
                  <th scope="row">{currentPage * orderLimit + index + 1}</th>
                  <td
                    className="darkData darkDataClick text-center"
                    onClick={() => navigate(`/orders/${item._id}`)}
                    style={{ cursor: "pointer" }}
                  >
                    {item._id}
                  </td>
                  <td className="darkData text-center">
                    {formatDateFromMongoDB(item.createdAt)}
                  </td>
                  <td className="darkData text-center">{item.shipping_name}</td>
                  <td className="lightData text-center">
                    {item.product.length}
                  </td>
                  <td className="lightData text-center">{item.grand_total}</td>
                  <td className="coloredData text-center">
                    {item.accept_status && (
                      <OrdersDropdown
                        status={"Accepted"}
                        id={item._id}
                        setOrders={setOrders}
                      />
                    )}
                    {item.cancel_status && (
                      <OrdersDropdown
                        status={"Cancelled"}
                        id={item._id}
                        setOrders={setOrders}
                      />
                    )}
                    {item.deliver_status && (
                      <OrdersDropdown
                        status={"Delivered"}
                        id={item._id}
                        setOrders={setOrders}
                      />
                    )}
                    {item.pending_status && (
                      <OrdersDropdown
                        status={"Pending"}
                        id={item._id}
                        setOrders={setOrders}
                      />
                    )}
                    {item.shipped_status && (
                      <OrdersDropdown
                        status={"Shipped"}
                        id={item._id}
                        setOrders={setOrders}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="d-flex justify-content-end">
            <ReactPaginate
              previousLabel="<"
              nextLabel=">"
              pageCount={pageCount}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
              pageClassName="page-item"
              pageLinkClassName="page-link custom-page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link custom-page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link custom-page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link custom-page-link"
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
            />
          </div>
        </div>
      ) : (
        <ListLoader rows={10} cols={7}></ListLoader>
      )}
    </>
  );
}

export default OrdersMain;