import { faList, faStore } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { FaWhatsapp, FaWhatsappSquare } from 'react-icons/fa'
import ReactSwitch from 'react-switch'
import { BASE_URL, cookieFetcher } from '../../../helper'
import Swal from 'sweetalert2'
import StoreStatusModal from '../EditProfileModals/StoreStatusModal'

function StoreStatusForm() {
  const [store, setStore] = useState(true)
  const [whatsapp, setWhatsapp] = useState(true)
  const [order, setOrder] = useState(true)
  const [firstcall, setFirstCall] = useState()
  const [modalOpen, setModalOpen] = useState(false)
 console.log(order,"order");
console.log(whatsapp,"whatsapp");
  const fetcher = async () => {
    const response = await fetch(`${BASE_URL}/setting/shop`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${cookieFetcher('token')}`,
      },
    })
    const res = await response.json()
    if (res.status === 200) {
      setStore(res.message.shop_status)
      setOrder(res.message.order_status)
      setWhatsapp(res.message.whatsapp_status)
    }
  }

  const storeStatusToggle = async (type, value) => {
    if (type === 'store') {
      const formdata = new FormData()
      formdata.append('shop_status', value)
      const response = await fetch(
        `${BASE_URL}/setting`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${cookieFetcher('token')}`,
            'Content-Type': 'Application/JSON',
          },
          body: JSON.stringify({ shop_status: value }),
        }
      )
      const res = await response.json()
      if (response.ok) {
        // Swal.fire({
        //   // icon: 'success',
        //   // title: 'Success',
        //   // text: 'Status Updated Successfully!',
        // })
        fetcher()
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    }
    if (type === 'whatsapp') {
      const formdata = new FormData()
      formdata.append('whatsapp_status', value)
      const response = await fetch(
        `${BASE_URL}/setting`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${cookieFetcher('token')}`,
            'Content-Type': 'Application/JSON',
          },
          body: JSON.stringify({ whatsapp_status: value }),
        }
      )
      const res = await response.json()
      // console.log('API Response:', response,response.statusText)
      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Status Updated Successfully!',
        })
        fetcher()
      } else {
        Swal.fire({
          icon: 'error',
          title: 'yashOops...',
          text: 'Something went wrong!',
        })
      }
    }
    if (type === 'order') {
      const formdata = new FormData()
      formdata.append('order_status', value)
      const response = await fetch(
        `${BASE_URL}/setting`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${cookieFetcher('token')}`,
            'Content-Type': 'Application/JSON',
          },
          body: JSON.stringify({ order_status: value }),
        }
      )
      const res = await response.json()
      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Status Updated Successfully!',
        })
        fetcher()
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    }
  }

  useEffect(() => {
    fetcher()
  }, [firstcall])

  return (
    <div className='storeStatusForm'>
      <div className='storeStatusTitleForm'>Store Status</div>
      <div className='storeStatusFormComponent'>
        <div className='storeStatusFormCompLeft'>
          <FontAwesomeIcon
            color='gray'
            icon={faStore}
            style={{ fontSize: '25px' }}
          />
          <div className='storeStatusTitle'>
            <div className='subscetionTitle'>Store Status</div>
            <div className='subsectionContent'>
              Hide/Show your store to user
            </div>
          </div>
        </div>
        <div className='storeStatusFormCompRight'>
            <ReactSwitch
              onChange={(e) => {
                console.log(e)
                if (e === false) {
                  setModalOpen(true)
                }
                setStore(e)
                storeStatusToggle('store', e)
              }}
              checked={store}
              uncheckedIcon=''
              checkedIcon=''
              onColor='#009ef7'
              offColor='#ff0000'
            ></ReactSwitch>
        </div>
      </div>
      <div className='storeStatusFormComponent'>
        <div className='storeStatusFormCompLeft'>
          <FontAwesomeIcon
            color='gray'
            style={{ fontSize: '25px' }}
            icon={faList}
          />
          <div className='storeStatusTitle'>
            <div className='subscetionTitle'>Order Online/Offline</div>
            <div className='subsectionContent'>
              {' '}
              Allow users to order online/offline
            </div>
          </div>
        </div>
        <div className='storeStatusFormCompRight'>
          <ReactSwitch
            onChange={(e) => {
              console.log(e)
              setOrder(e)
              storeStatusToggle('order', e)
            }}
            checked={order}
            uncheckedIcon=''
            checkedIcon=''
            onColor='#009ef7'
            offColor='#ff0000'
          ></ReactSwitch>
        </div>
      </div>
      <div className='storeStatusFormComponent'>
        <div className='storeStatusFormCompLeft'>
          <FaWhatsapp color='gray' size={25}></FaWhatsapp>
          <div className='storeStatusTitle'>
            <div className='subscetionTitle'>Show WhatsApp Number</div>
            <div className='subsectionContent'>
              Hide/Show WhatsApp on website
            </div>
          </div>
        </div>
        <div className='storeStatusFormCompRight'>
          <ReactSwitch
            onChange={(e) => {
              console.log(e)
              setWhatsapp(e)
              storeStatusToggle('whatsapp', e)
            }}
            checked={whatsapp}
            uncheckedIcon=''
            checkedIcon=''
            onColor='#009ef7'
            offColor='#ff0000'
          ></ReactSwitch>
        </div>
      </div>
      {/* <div className='cancelAndSaveButton'>
        <div className='cancelButton'>Cancel</div>
        <div className='saveButton'>Save Changes</div>
      </div> */}
      <StoreStatusModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      ></StoreStatusModal>
    </div>
  )
}

export default StoreStatusForm
