import React from 'react'
import './NavigationBoxSettings.css'
import { useState } from 'react'
import { useEffect } from 'react'
import { BASE_URL, cookieFetcher } from '../../helper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClock,
  faFilePen,
  faHashtag,
  faShopLock,
  faStore,
  faTruck,
  faWarehouse,
  faEye,
} from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'

function NavigationBoxSettings({ photo }) {
  const [username, setUsername] = useState('')
  const [userLink, setUserLink] = useState('lofaz.in/')
  const [imageUrl, setImageUrl] = useState('  ')
  const navigate = useNavigate()
// console.log(`${username}hihii`);
useEffect(() => {
  const fetcher = async () => {
    const response = await fetch(
      `${BASE_URL}/vendor/visitor/${cookieFetcher('username')}`
    );
    const res = await response.json();
    if (res.status === 201) {
      // console.log(res.data.username,"hi");
      setUsername(res.data.username);
      setUserLink(`lofaz.in/${res.data.username}`);
      if (res.data.photo) {
        setImageUrl(res.data.photo);
        // Check if res.data.photo is a string or array before accessing its length
        if (typeof res.data.photo === 'string' || Array.isArray(res.data.photo)) {
          if (res.data.photo.length > 0) {
            setImageUrl(res.data.photo);
            // console.log(`${res.data.photo}   photo`)
          }
        }
      }
    } else {
      console.log(res.message);
    }
    setImageUrl(res.data.photo)
    setUsername(res.data.username)
    
  };

  fetcher();
}, []);


  return (
    <div className='navigationBoxSettings'>
      <div className='boxWithUrlProfile'>

        <div className='navigationBoxUsername'>
          <div className='nvBoxImage'>
            <img
              src={imageUrl}
              width='38px'
              height='38px'
              style={{
                borderRadius: '5px',
                border: '1px solid black',
                padding: '5px',
              }}
            ></img>
          </div>
          <div className='nvBoxUsernameAndLink'>
            <div
              style={{
                textAlign: 'start',
                lineHeight: '20px',
                fontWeight: '600',
                fontSize: '16px',
              }}
            >
              {username}
            </div>
            <div
              onClick={() => {
                window.open(`https://${userLink}/${username}`, '_blank')
              }}
              style={{
                textAlign: 'start',
                lineHeight: '18px',
                fontWeight: '400',
                fontSize: '13px',
                color: '#009ef7',
                cursor: 'pointer',
              }}
            >
              {userLink}{username}
            </div>
          </div>
        </div>
        <div className='nvBoxStoreView'>

        <span class="material-symbols-outlined" style={{
            // color: 'white',
            // marginBottom: '-4px',
            // width:"3px",
            fontSize: '25px',
            cursor: 'pointer',
          }} onClick={() => {
            window.open(`https://${userLink}/${username}`, '_blank')
            // window.open(`https://${userLink}`, '_blank')
          }}>
           open_in_new
          </span>
        </div>
      </div>
      <div className='navigationBoxOptions'>
        <div className='navBoxOption'>
          {/* <FontAwesomeIcon icon={faStore} /> */}
          <span class="material-symbols-outlined">
            storefront
          </span>
          <p
            onClick={() => {
              navigate('/settings')
            }}
          >
            Business Details
          </p>
        </div>
        <div className='navBoxOption'>
          {/* <FontAwesomeIcon icon={faShopLock} /> */}
          <span class="material-symbols-outlined">
            toggle_on
          </span>
          <p
            onClick={() => {
              navigate('/settings/storeStatus')
            }}
          >
            {' '}
            Store Status
          </p>
        </div>
        <div className='navBoxOption'>
          {/* <FontAwesomeIcon icon={faClock} /> */}
          <span class="material-symbols-outlined">
            schedule
          </span>
          <p
            onClick={() => {
              navigate('/settings/timings')
            }}
          >
            {' '}
            Store Timing
          </p>
        </div>
        <div className='navBoxOption'>
          {/* <FontAwesomeIcon icon={faWarehouse} /> */}
          <span class="material-symbols-outlined">
            warehouse
          </span>
          <p
            onClick={() => {
              navigate('/settings/timings')
            }}
          >
            Warehouse
          </p>
        </div>
        <div className='navBoxOption'>
          {/* <FontAwesomeIcon icon={faTruck} /> */}
          <span class="material-symbols-outlined">
            local_shipping
          </span>
          <p
            onClick={() => {
              navigate('/settings/shippingAndDelivery')
            }}
          >
            Shipping and Delivery
          </p>
        </div>
        <div className='navBoxOption'>
          {/* <FontAwesomeIcon icon={faHashtag} /> */}
          <span class="material-symbols-outlined">
            tag
          </span>
          <p
            onClick={() => {
              navigate('/settings/socialMedia')
            }}
          >
            {' '}
            Social Media Links
          </p>
        </div>
        <div className='navBoxOption'>
          {/* <FontAwesomeIcon icon={faFilePen} /> */}
          <span class="material-symbols-outlined">
            description
          </span>
          <p
            onClick={() => {
              navigate('/settings/privacyPolicy')
            }}
          >
            Policies
          </p>
        </div>
      </div>
      {/* <div className='navBButtons'>
        <div className='navBCancelButton'>Cancel</div>
        <div className='navBSaveButton'>Save</div>
      </div> */}
    </div>
  )
}

export default NavigationBoxSettings
