import React, { useState } from 'react'
import Sidebar from '../../components/Sidebar/Sidebar'
import OrdersHeader from '../Orders/OrdersHeader'
import Footer from '../../components/Footer/Footer'
import './Manage.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQrcode } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { cookieFetcher } from '../../helper'

function Manage() {
  const [sidebar, setSidebar] = useState(true)
  const navigate = useNavigate()
  return (
    <div className='managePage'>
      <Helmet
        title='Manage'
        link={{ rel: 'icon', type: 'image/png', href: cookieFetcher('photo') }}
      ></Helmet>
      <Sidebar sidebar={sidebar}></Sidebar>
      <div className='managePageMain'>
        <div className='managePageMainTop'>
          <OrdersHeader
            sidebar={sidebar}
            setSidebar={setSidebar}
          ></OrdersHeader>
          <div className='managePageMainSec'>
            <div
              className='display-grid-item manage_box'
              onClick={() => {
                navigate('qrCode')
              }}
            >
              <div className='display-grid-item-line'>
                <FontAwesomeIcon icon={faQrcode}></FontAwesomeIcon>
              </div>
              <div className='display-grid-item-line display-grid-item-line-title'>
                QR Code
              </div>
              <div className='display-grid-item-line display-grid-item-line-subtitle'>
                Share your store with your peers
              </div>
            </div>
            <div className='display-grid-item manage_box'
            onClick={() => {
              navigate('slider')
            }}>
              <div className='display-grid-item-line d-flex align-items-center'>
                <FontAwesomeIcon icon={faQrcode}></FontAwesomeIcon>
                {/* <div className="badge badge-info fw-normal mx-3">Coming Soon</div> */}
              </div>
              <div className='display-grid-item-line display-grid-item-line-title'>
              Slider Images/Carousel  
              </div>
              <div className='display-grid-item-line display-grid-item-line-subtitle'>
                Share your store with your peers
              </div>
            </div>
           
          </div>
        </div>
        <div className='managePageMainBottom'>
          <Footer></Footer>
        </div>
      </div>
    </div>
  )
}

export default Manage
