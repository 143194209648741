import React from 'react'   
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { BASE_URL, cookieFetcher } from '../../../helper'
import { useState } from 'react'
import Swal from 'sweetalert2'

function StoreStatusModal({ modalOpen, setModalOpen }) {
  const [value, setValue] = useState(-1)
  const toggler = () => {
    setModalOpen(!modalOpen)
  }
  const btncheck=()=>{
    if (modalOpen) {
      setModalOpen(!modalOpen)
      // console.log(modalOpen,'btncheck cancel');
    }
  }

  const timingOptions = [
    { label: 'In 1 Hour', value: 1 },
    { label: 'In 2 Hours', value: 2 },
    { label: 'In 5 Hours', value: 5 },
    { label: 'In 1 Day', value: 24 },
    { label: 'I will open by myself', value: -1 },
  ]

  const shopCloser = async (value) => {
    const response = await fetch(
      `${BASE_URL}/setting`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${cookieFetcher('token')}`,
          'Content-Type': 'Application/JSON',
        },
        body: JSON.stringify({ open_after: value, shop_status: false }),
      }
    )
    const res = await response.json()
    if (response.statusText) {
      Swal.fire({
        icon: 'success',
        title: 'Store closed!',
        text: 'Status changed Successfully',
      })
    }
  }

  return (
    <div className='storeStatusModal '>
      <Modal isOpen={modalOpen} toggle={toggler}>
        <ModalHeader toggle={toggler}>When will the shop open?</ModalHeader>
        <ModalBody>
  {timingOptions.map((item) => {
    return (
      <div className='listOptionItem  p-3  cursor-pointer flex items-center mr-2'  key={item.value} onClick={() => setValue(item.value)}>
      <input
        onClick={() => setValue(item.value)}
        type='radio'
        name='timingOption'
        checked={item.value === value}
        className='mr-2 cursor-pointer '
      />
      <label className='cursor-pointer'>{item.label}</label>
    </div>
      
    );
  })}
</ModalBody>

        <ModalFooter>
          <Button
            color='primary'
            onClick={() => {
              shopCloser(value)
              toggler()
            }}
          >
            Close Store
          </Button>{' '}
          <Button
            color='secondary'
            onClick={() => {
              btncheck()
              shopCloser(value)
              // toggler()
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default StoreStatusModal
