import React from 'react'
import { BsSearch } from 'react-icons/bs'
import { MdNotificationsActive } from 'react-icons/md'
import { LuClipboardList } from 'react-icons/lu'
import { BiSolidMessageSquareDetail } from 'react-icons/bi'
import { CiLight } from 'react-icons/ci'
import { PiSquaresFour } from 'react-icons/pi'
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap'
import { logout } from '../../helper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesLeft, faAnglesRight } from '@fortawesome/free-solid-svg-icons'
import ReactSwitch from 'react-switch'

function OrdersHeader({ sidebar, setSidebar }) {
  return (
    <div className='ordersHeader'>
      {sidebar ? (
        <div
          className='ordersHeaderLeft'
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setSidebar(!sidebar)
          }}
        >
          <FontAwesomeIcon icon={faAnglesLeft} />
        </div>
      ) : (
        <div
          className='ordersHeaderLeft closedIcon'
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setSidebar(!sidebar)
          }}
        >
          <FontAwesomeIcon icon={faAnglesRight}></FontAwesomeIcon>
        </div>
      )}

   

      <div className='ordersHeaderRight'>
        <div className='profileImage'>
          <UncontrolledDropdown>
            <DropdownToggle
              style={{ background: 'white', border: 'none', padding: '0px' }}
            >
              <img
                className='profile_toggle'
                border-radius='5px'
                src='https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?cs=srgb&dl=pexels-pixabay-220453.jpg&fm=jpg'
              ></img>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem>
                  
              <ReactSwitch
            onChange={(e) => {
              // console.log(e)
              // if (e === false) {
                // setModalOpen(true)
              // }
              // setStore(e)
              // storeStatusToggle('store', e)
            }}
            // checked={store}
            uncheckedIcon=''
            checkedIcon=''
            onColor='#009ef7'
            offColor='#ff0000'
          ></ReactSwitch>
              </DropdownItem>
              <DropdownItem

                onClick={() => {
                  logout()
                }}
              >
                Logout
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    </div>
  )
}

export default OrdersHeader
