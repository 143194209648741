import React, { useRef, useState } from 'react'
import '../AddCatalogForm/AddCatalogForm.css'
import { components } from '../../pages/Orders/OrdersHelper'
import Select from 'react-select'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import FourImageSelector from './FourImageSelector'
import { createCatalog } from '../AddCatalogForm/CatalogCreator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'
import { editCatalog } from './CatalogEditor'
import NavigationBox from '../NavigationBox/NavigationBox'
import FormLoader from '../FormLoader/FormLoader'

function EditCatalogForm({ catalog }) {
  const [featureImageLink, setFeatureImageLink] = useState(
    'https://res.cloudinary.com/nikhil-patil-web/image/upload/v1688966827/LofazInputImage_narqjv.png'
  )
  const [featureImage, setFeatureImage] = useState(
    'https://res.cloudinary.com/nikhil-patil-web/image/upload/v1688966827/LofazInputImage_narqjv.png'
  )
  const [filesSelected, setFilesSelected] = useState(false)
  const [images, setImages] = useState(new Array(4))
  const [catalogName, setCatalogName] = useState('')
  const [catalogStatus, setCatalogStatus] = useState(true)
  const [loading, setLoading] = useState(false)
  const [formLoader, setFormLoader] = useState(true)

  const inputFile = useRef(null)
  const inputMultipleFile = useRef(null)

  const onButtonClick = () => {
    inputFile.current.click()
  }

  const onButtonClickMultiple = () => {
    inputMultipleFile.current.click()
  }

  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0]
    if (!fileObj) {
      return
    }
    setFeatureImage(event.target.files[0])
    console.log('setting')
    setFeatureImageLink(URL.createObjectURL(event.target.files[0]))
  }

  const handleMultipleFileChange = (e) => {
    if (e.target && e.target.files.length > 0) {
      let tempArr = new Array(4)
      if (e.target.files[0]) {
        tempArr[0] = e.target.files[0]
      }
      if (e.target.files[1]) {
        tempArr[1] = e.target.files[1]
      }
      if (e.target.files[2]) {
        tempArr[2] = e.target.files[2]
      }
      if (e.target.files[3]) {
        tempArr[3] = e.target.files[3]
      }
      setImages(tempArr)
      setFilesSelected(true)
    }
  }

  useEffect(() => {
    setFormLoader(true)
    if (catalog.photo && catalog.photo[0] && catalog.photo[0].location) {
      setFeatureImageLink(catalog.photo[0].location)
      setFeatureImage(catalog.photo[0])
    }
    if (catalog.title) {
      setCatalogName(catalog.title)
    }
    if (catalog.active) {
      setCatalogStatus(catalog.active)
    } else {
      setCatalogStatus(false)
    }
    setFormLoader(false)
  }, [catalog])

  return (
    <>
      <>
        <NavigationBox
          edit={true}
          id={catalog?._id}
          type={'catalog'}
          catalogStatus={catalogStatus}
          setCatalogStatus={setCatalogStatus}
          editCatalogName={catalogName}
          editCatalogImages={images}
          editCatalogFeatureImage={featureImage}
        ></NavigationBox>
        {!formLoader ? (
          <div className='addProductForm'>
            {/*Here begins the first part of the add product form where user inputs the media files related to the product */}
            <div
              className='addProductFormComponent productMediaForm'
              id='productMedia'
            >
              <div className='productMediaLeft'>
                <div
                  className='productMediaTitle'
                  style={{
                    textAlign: 'left',
                    color: '#181c32',
                    fontWeight: '600',
                    fontSize: '20px',
                    lineHeight: '23px',
                  }}
                >
                  Media
                </div>

                <div
                  className='productMediaFeatureImage'
                  onClick={() => onButtonClick()}
                >
                  <input
                    type='file'
                    style={{ display: 'none' }}
                    ref={inputFile}
                    onChange={(e) => {
                      handleFileChange(e)
                    }}
                  ></input>
                  <img
                    src={featureImageLink}
                    style={{
                      border: 'dashed #009ef7 1px',
                      borderRadius: '5px',
                      boxShadow: '0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075)',
                      width: '160px',
                      height: '160px',
                    }}
                  ></img>
                </div>

                <div className='featureImageBottomText'>
                  <div
                    style={{
                      fontWeight: '700',
                      fontSize: '14px',
                      color: '#181c32',
                      lineHeight: '17px',
                    }}
                  >
                    Featured Image
                  </div>
                  <div
                    style={{
                      fontSize: '12px',
                      color: '#a1a5b7',
                      fontWeight: '400',
                    }}
                  >
                    *1000 × 1000 size
                  </div>
                  <div
                    style={{
                      fontSize: '12px',
                      color: '#a1a5b7',
                      fontWeight: '400',
                    }}
                  >
                    *less than 1 mb
                  </div>
                  <div></div>
                </div>
              </div>
              {/* <FourImageSelector
            images={images}
            setImages={setImages}
          ></FourImageSelector> */}
            </div>

            {/* Here begins the second part of the form in which the user fills the product info */}

            <div
              className='addProductFormComponent productInfoComponent'
              id='productInfo'
            >
              <div className='productInfoTitle'>Catalog Information</div>
              <div className='productNameAndCategoryInputs'>
                <div className='inputName'>
                  <div
                    className='inputHeading'
                    style={{
                      textAlign: 'left',
                      color: '#3f4254',
                      fontWeight: '500',
                    }}
                  >
                    Catalog Name
                  </div>
                  <input
                    placeholder='Catalog Name'
                    style={{
                      width: '100%',
                      border: ' solid 1px #cccccc',
                      padding: '7px',
                      paddingLeft: '10px',
                      borderRadius: '5px',
                      fontFamily: 'Inter',
                      fontSize: '15px',
                      fontWeight: '500',
                    }}
                    value={catalogName}
                    onChange={(e) => {
                      setCatalogName(e.target.value)
                    }}
                  ></input>
                </div>
              </div>
            </div>

            <div
              className='addProductFormComponent prodcutFormSEOComponent'
              id='seo'
            >
              <div className='seoFormComp seoTitle'>SEO</div>
              <div className=' seoFormComp metaTagTile'>
                <div className='seoCompSubtitles'>Meta Tag Title</div>
                <input placeholder='Meta tag name'></input>
                <div className='seoCompInfo'>
                  Set a meta tag title. Recommended to be simple and precise
                  keywords.
                </div>
              </div>
              <div className=' seoFormComp metaTagDescription'>
                <div className='seoCompSubtitles'>Meta Tag Description</div>
                <div className=''>
                  <ReactQuill
                    theme='snow'
                    style={{
                      textAlign: 'left',
                      color: '#a1a5b7',
                    }}
                    placeholder='Type your text here........'
                  ></ReactQuill>
                </div>
                <div className='seoCompInfo'>
                  Set a meta tag description to the product for increased SEO
                  ranking.
                </div>
              </div>
              <div className=' seoFormComp tags'>
                <div className='seoCompSubtitles tagsSubtitle'>
                  Tags <span style={{ color: 'red' }}>*</span>
                </div>
                <input
                  placeholder=''
                  style={{
                    background: '#f9f9f9',
                    border: 'none',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    paddingLeft: '10px',
                    paddingTop: '7px',
                    paddingBottom: '7px',
                    color: '#5e6278',
                    fontWeight: 500,
                  }}
                  defaultValue='Important, Urgent'
                ></input>
              </div>
              <div className=' seoFormComp metaTagKeywords'>
                <div className='seoCompSubtitles'>Meta Tag Keywords</div>
                <input placeholder=''></input>
                <div className='seoCompInfo'>
                  Set a list of keywords that the product is related to.
                  Separate the keywords by adding a comma{' '}
                  <span
                    style={{
                      background: '#f1f3f8',
                      paddingTop: '2px',
                      paddingLeft: '8px',
                      paddingRight: '8px',
                      borderRadius: '3px',
                      paddingBottom: '5px',
                      color: 'purple',
                    }}
                  >
                    ,
                  </span>{' '}
                  between each keyword.
                </div>
              </div>
            </div>
            <div className='addProductFormComponent saveButtonComponent'>
              <div className='cancelButton'>Cancel</div>
              {!loading ? (
                <div
                  className='saveButton'
                  onClick={(e) => {
                    setLoading(true)
                    editCatalog(
                      featureImage,
                      images,
                      catalogName,
                      catalogStatus,
                      catalog?._id
                    )
                  }}
                >
                  Save Changes
                </div>
              ) : (
                <div className='saveLoading' style={{ background: '' }}>
                  <FontAwesomeIcon icon={faSpinner} spin>
                    {' '}
                  </FontAwesomeIcon>
                </div>
              )}
            </div>
          </div>
        ) : (
          <FormLoader></FormLoader>
        )}
      </>
    </>
  )
}

export default EditCatalogForm
