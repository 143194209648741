import React, { useEffect, useRef, useState } from 'react'
import './AddCatalogForm.css'
import { components } from '../../pages/Orders/OrdersHelper'
import Select from 'react-select'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import FourImageSelector from './FourImageSelector'
import { createCatalog } from './CatalogCreator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import NavigationBox from '../NavigationBox/NavigationBox'
import { encode } from 'base-64'

function AddCatalogForm() {
  const [sMTitle, setSMTitle] = useState('')
  const [sMDesc, setSMDesc] = useState('')
  const [sMMetaTags, setSMMetaTags] = useState([])
  const [sMMetaTagsInterim, setSMMetaTagsInterim] = useState('')

  const [featureImageLink, setFeatureImageLink] = useState(
    'https://res.cloudinary.com/nikhil-patil-web/image/upload/v1688966827/LofazInputImage_narqjv.png'
  )
  const [featureImage, setFeatureImage] = useState(
    'https://res.cloudinary.com/nikhil-patil-web/image/upload/v1688966827/LofazInputImage_narqjv.png'
  )
  const [filesSelected, setFilesSelected] = useState(false)
  const [images, setImages] = useState(new Array(4))
  const [catalogName, setCatalogName] = useState('')
  const [catalogStatus, setCatalogStatus] = useState(true)
  const [loading, setLoading] = useState(false)

  const inputFile = useRef(null)
  const inputMultipleFile = useRef(null)

  function splitStringByComma(inputString) {
    // Use the split method to split the string by commas
    const wordsArray = inputString.split(',')

    // Trim whitespace from each word and remove empty strings
    const cleanedArray = wordsArray
      .map((word) => word.trim())
      .filter((word) => word !== '')

    return cleanedArray
  }

  const checker = (s) => {
    if (s.includes(',')) {
      const arr = splitStringByComma(s)
      setSMMetaTags(arr)
    }
  }

  const onButtonClick = () => {
    inputFile.current.click()
  }

  const onButtonClickMultiple = () => {
    inputMultipleFile.current.click()
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0]
    if (!fileObj) {
      return
    }
    const file = event.target.files[0]
    const myString = await convertBase64(file)

    var n = 23
    var substringToEnd = myString.substring(n)

    setFeatureImage(substringToEnd)
    setFeatureImageLink(URL.createObjectURL(event.target.files[0]))
  }

  const handleMultipleFileChange = (e) => {
    if (e.target && e.target.files.length > 0) {
      let tempArr = new Array(4)
      if (e.target.files[0]) {
        tempArr[0] = e.target.files[0]
      }
      if (e.target.files[1]) {
        tempArr[1] = e.target.files[1]
      }
      if (e.target.files[2]) {
        tempArr[2] = e.target.files[2]
      }
      if (e.target.files[3]) {
        tempArr[3] = e.target.files[3]
      }
      setImages(tempArr)
      setFilesSelected(true)
    }
  }

  useEffect(() => {
    console.log(sMDesc)
    console.log(sMMetaTags)
    console.log(sMTitle)
  }, [sMDesc, sMMetaTags, sMTitle])

  return (
    <>
      <NavigationBox
        catalogStatus={catalogStatus}
        setCatalogStatus={setCatalogStatus}
        type={'catalog'}
        addCatalogFeatureImage={featureImage}
        addCatalogName={catalogName}
        addCatalogImages={images}
        edit={false}
        catalogSeoTitle={sMTitle}
        catalogSeoDesc={sMDesc}
        catalogSeoTags={sMMetaTags}
      ></NavigationBox>
      <div className='addProductForm'>
        {/*Here begins the first part of the add product form where user inputs the media files related to the product */}
        <div
          className='addProductFormComponent productMediaForm'
          id='productMedia'
        >
          <div className='productMediaLeft'>
            <div
              className='productMediaTitle'
              style={{
                textAlign: 'left',
                color: '#181c32',
                fontWeight: '600',
                fontSize: '20px',
                lineHeight: '23px',
              }}
            >
              Media
            </div>

            <div
              className='productMediaFeatureImage'
              onClick={() => onButtonClick()}
            >
              <input
                type='file'
                style={{ display: 'none' }}
                ref={inputFile}
                onChange={(e) => {
                  handleFileChange(e)
                }}
              ></input>
              <img
                src={featureImageLink}
                style={{
                  border: 'dashed #009ef7 1px',
                  borderRadius: '5px',
                  boxShadow: '0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075)',
                  width: '160px',
                  height: '160px',
                }}
              ></img>
            </div>

            <div className='featureImageBottomText'>
              <div
                style={{
                  fontWeight: '700',
                  fontSize: '14px',
                  color: '#181c32',
                  lineHeight: '17px',
                }}
              >
                Featured Image
              </div>
              <div
                style={{
                  fontSize: '12px',
                  color: '#a1a5b7',
                  fontWeight: '400',
                }}
              >
                *1000 × 1000 size
              </div>
              <div
                style={{
                  fontSize: '12px',
                  color: '#a1a5b7',
                  fontWeight: '400',
                }}
              >
                *less than 1 mb
              </div>
              <div></div>
            </div>
          </div>
          {/* {!filesSelected ? (
            <div className='productMediaRight'>
              <input
                type='file'
                multiple
                style={{ display: 'none' }}
                ref={inputMultipleFile}
                onChange={(e) => {
                  handleMultipleFileChange(e)
                }}
              ></input>
              <div
                className='dropFilesSection'
                onClick={() => onButtonClickMultiple()}
              >
                <div className='dropFilesIcon'>
                  <img src='https://res.cloudinary.com/nikhil-patil-web/image/upload/v1688971931/LofazUploadImage_bykfaq.png'></img>
                </div>
                <div className='dropFilesTexts'>
                  <div
                    style={{
                      textAlign: 'left',
                      fontSize: '17px',
                      fontWeight: '600',
                      color: '#181c32',
                    }}
                  >
                    Drop files here or click to upload.
                  </div>
                  <div
                    style={{
                      textAlign: 'left',
                      fontSize: '13px',
                      fontWeight: '500',
                      color: '#b5b5c3',
                    }}
                  >
                    Upload up to 4 files
                  </div>
                </div>
              </div>
              <div className='galleryImagesText'>
                <div
                  style={{
                    textAlign: 'left',
                    fontWeight: '700',
                    fontSize: '14px',
                    color: '#181c32',
                    lineHeight: '17px',
                  }}
                >
                  Gallery Images
                </div>
                <div
                  style={{
                    textAlign: 'left',

                    fontSize: '12px',
                    color: '#a1a5b7',
                    fontWeight: '400',
                  }}
                >
                  *1000x1000 size with size less than 1 mb
                </div>
              </div>
            </div>
          ) : (
            <FourImageSelector
              images={images}
              setImages={setImages}
            ></FourImageSelector>
          )} */}
        </div>

        {/* Here begins the second part of the form in which the user fills the product info */}

        <div
          className='addProductFormComponent productInfoComponent'
          id='productInfo'
        >
          <div className='productInfoTitle'> Category Information</div>
          <div className='productNameAndCategoryInputs'>
            <div className='inputName'>
              <div
                className='inputHeading'
                style={{
                  textAlign: 'left',
                  color: '#3f4254',
                  fontWeight: '500',
                }}
              >
                Category Name
              </div>
              <input
                placeholder='Catalog Name'
                style={{
                  width: '100%',
                  border: ' solid 1px #cccccc',
                  padding: '7px',
                  paddingLeft: '10px',
                  borderRadius: '5px',
                  fontFamily: 'Inter',
                  fontSize: '15px',
                  fontWeight: '500',
                }}
                value={catalogName}
                onChange={(e) => {
                  setCatalogName(e.target.value)
                }}
              ></input>
            </div>
          </div>
        </div>

        <div
          className='addProductFormComponent prodcutFormSEOComponent'
          id='seo'
        >
          <div className='seoFormComp seoTitle'>SEO</div>
          <div className=' seoFormComp metaTagTile'>
            <div className='seoCompSubtitles'>Meta Tag Title</div>
            <input
              placeholder='Meta tag name'
              onChange={(e) => {
                setSMTitle(e.target.value)
              }}
            ></input>
            <div className='seoCompInfo'>
              Set a meta tag title. Recommended to be simple and precise
              keywords.
            </div>
          </div>
          <div className=' seoFormComp metaTagDescription'>
            <div className='seoCompSubtitles'>Meta Tag Description</div>
            <div className=''>
              <ReactQuill
                theme='snow'
                style={{
                  textAlign: 'left',
                  color: '#a1a5b7',
                }}
                onChange={(e) => {
                  setSMDesc(e)
                }}
                placeholder='Type your text here........'
              ></ReactQuill>
            </div>
            <div className='seoCompInfo'>
              Set a meta tag description to the product for increased SEO
              ranking.
            </div>
          </div>
          <div className=' seoFormComp tags'>
            <div className='seoCompSubtitles tagsSubtitle'>
              Tags <span style={{ color: 'red' }}>*</span>
            </div>
            <input
              placeholder='Important, Urgent'
              style={{
                background: '#f9f9f9',
                border: 'none',
                fontFamily: 'Inter',
                fontSize: '14px',
                paddingLeft: '10px',
                paddingTop: '7px',
                paddingBottom: '7px',
                color: '#5e6278',
                fontWeight: 500,
              }}
              onChange={(e) => {
                if (e.target.value.trim().length === 0) {
                  setSMMetaTags([])
                } else {
                  setSMMetaTagsInterim(e.target.value)
                  checker(e.target.value)
                }
              }}
            ></input>
            <div className='seoCompInfo'>
              Set a list of keywords that the product is related to. Separate
              the keywords by adding a comma{' '}
              <span
                style={{
                  background: '#f1f3f8',
                  paddingTop: '2px',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  borderRadius: '3px',
                  paddingBottom: '5px',
                  color: 'purple',
                }}
              >
                ,
              </span>{' '}
              between each keyword.
            </div>
          </div>
          <div className='metaTagsListHolder'>
            {sMMetaTags.length > 0 &&
              sMMetaTags.map((item) => {
                return <div className='tagHolderHere'>{item}</div>
              })}
          </div>
          {/* <div className=' seoFormComp metaTagKeywords'>
            <div className='seoCompSubtitles'>Meta Tag Keywords</div>
            <input placeholder=''></input>
            <div className='seoCompInfo'>
              Set a list of keywords that the product is related to. Separate
              the keywords by adding a comma{' '}
              <span
                style={{
                  background: '#f1f3f8',
                  paddingTop: '2px',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  borderRadius: '3px',
                  paddingBottom: '5px',
                  color: 'purple',
                }}
              >
                ,
              </span>{' '}
              between each keyword.
            </div>
          </div> */}
        </div>
        <div className='addProductFormComponent saveButtonComponent'>
          <div className='cancelButton'>Cancel</div>
          {!loading ? (
            <div
              className='saveButton'
              onClick={(e) => {
                setLoading(true)
                createCatalog(featureImage, images, catalogName, catalogStatus)
              }}
            >
              Save Changes
            </div>
          ) : (
            <div className='saveLoading' style={{ background: '' }}>
              <FontAwesomeIcon icon={faSpinner} spin>
                {' '}
              </FontAwesomeIcon>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default AddCatalogForm
