import React, { useEffect, useState } from 'react'
import './ListLoader.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

function ListLoader({ cols, rows }) {
  const [arr, setArr] = useState(Array.from(Array(rows * cols), () => 0))

  return (
    <div
      className='listLoader'
      style={{
        display: 'grid',
        gridTemplateRows: `repeat(${rows}, 1fr)`,
        gridTemplateColumns: `repeat(${cols}, 1fr)`,
        gap: '10px 20px',
        paddingLeft: '30px',
        paddingRight: '30px',
        paddingTop: '20px',
        paddingBottom: '20px',
      }}
    >
      {arr.map((item) => {
        return (
          <div className=''>
            <SkeletonTheme>
              <Skeleton></Skeleton>
            </SkeletonTheme>
          </div>
        )
      })}
    </div>
  )
}

export default ListLoader
